.widgets__grid-container {
  /* Fancy */
  --min-column-width: min(500px, 100%);

  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-column-width), 1fr));
}

.widgets__item-container {
  /* background-color: var(--bs-body-color); */
  background-color: white;
  border-radius: 8px;
  max-height: 550px;
  min-width: 300px;

  @media (max-width: 586px) {
    max-height: fit-content;
  }

  header {
    gap: 8px;
  }

  .call-to-action {
    font-size: 0.8rem;
    color: var(--bs-secondary);

    &:hover {
      color: var(--bs-primary);
    }
  }

  .select {
    flex: 0 0 140px;
  }

  .title {
    font-size: 1.1rem;
    font-weight: 600;
    text-align: left;
  }

  @media (max-width: 768px) {
    // margin-left: -15px;
    // margin-right: -15px;
    border-radius: 0;
  }
}

.widgets__empty-widget,
.widgets__error-widget {
  background-color: var(--bs-gray-100);
  height: 100%;
  color: var(--bs-gray-500);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 32px;
  text-align: center;

  display: grid;
  place-items: center;
  border: 1px solid var(--bs-gray-200);
}

/* 
  Colors are hardcodded, but this is the best solution until
  color variables are better handled. As of now it looks like we have to
  re-import bootstrap and a lot of unneccesary styles to use variables 
  from SCSS
*/
.widgets__error-widget {
  color: #e56975;
  border-color: #ed959d;
  background-color: #fceced;
}

.widget__placeholder-container {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;

  animation: breathe 0.4s infinite both ease-in-out;
}

.animations__breathe {
  animation: breathe 0.4s infinite both ease-in-out;
}

@keyframes breathe {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.widgets__grid-twocol-container {
  /* Fancy */
  --min-column-width: min(700px, 100%);

  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-column-width), 1fr));
}

.widgets__twocol-item-container {
  /* background-color: var(--bs-body-color); */
  background-color: white;
  border-radius: 8px;
  height: 350px;
}
