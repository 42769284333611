.dropdown-btn {
  width: 200px;
  height: 40px;
  border: gray solid 1px;
  border-style: solid;
  border-radius: 8px;
  background-color: white;
  margin-right: 10px;
  text-align: left;
  color: gray;

  .select-text {
    padding-left: 14px;
  }
}
.dropdown-container {
  border: black solid 1px;
  position: absolute;
  border-radius: 8px;
  background-color: white;
  width: 200px;
  display: flex;
  flex-direction: column;

  .dropdown-input {
    outline: none;
    height: 35px;
    margin: 5px;
    border-radius: 8px;
    border: gray solid 1px;
    padding-left: 4px;
  }

  .dropdown-options {
    background-color: white;
    border: none;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    text-align: left;
    padding-left: 5px;
  }
}
