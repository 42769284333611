.lb-dashboard {
  &__row-legend {
    // display: flex;
    // align-items: center;
    // justify-content: space-around;
    // padding-left: 0;

    .item {
      --legend-color: black;
      list-style: none;

      display: flex;
      flex-direction: column;
      align-items: center;

      .field-name {
        color: var(--bs-gray-500);
        font-size: 0.8rem;
      }

      .value {
        color: var(--legend-color);
        font-weight: 700;
        font-size: 1.1rem;
      }
    }
  }
}
