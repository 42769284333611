.card {
  border-radius: 8px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px;
  padding: 20px 0 20px;
  /* background-color: white; */
  height: min-content;
}

.card--login {
  width: 95%;
  max-width: 450px;
}

.card hr {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
  margin-bottom: 0.5rem;
}
