.ps__custom-button {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 12px 20px;
  width: 100%;
  min-height: 8rem;
  transition: background 450ms ease-in;
  background-color: var(--bs-white);
  border: none;

  &:hover {
    transition: background 200ms ease-in;
    background-color: var(--bs-gray-100);
  }

  &:active &__title,
  &:active &__body,
  &:active svg {
    color: var(--bs-white);
    stroke: var(--bs-white);
  }

  &:focus {
    background-color: var(--bs-gray-200);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  &__title {
    color: var(--bs-primary);
    font-weight: 600;
    font-size: 1.1rem;
  }

  &__body {
    color: var(--bs-gray-700);
    font-weight: 400;
    font-size: 0.8rem;
  }

  &__title,
  &__body {
    @media (min-width: 768px) {
      text-align: start;
    }
  }
}

.ps__price-insert {
  background-color: var(--bs-gray-100);

  &__secondary {
    margin-top: 0.2rem;
    font-size: 0.7rem;
    color: var(--bs-gray-600);
  }
}
