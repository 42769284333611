.onboardgrid__container {
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 52px;
    color: #68808d;
    text-align: center;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #68808d;
  }

  .sticky-container {
    position: sticky;
    top: 32px;
  }

  .action-group {
    display: flex;
    justify-content: flex-end;
  }

  .support-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
    gap: 16px;
    font-size: 14px;
    font-weight: 600;
    padding-top: 8px;

    .support {
      line-height: 1.5;
      color: #68808d;
    }

    .support-link {
      color: #2582ff;
      text-decoration: none;

      &:hover {
        text-decoration: revert;
      }
    }
  }

  .page-image {
    position: relative;
    margin-right: -48px;
    min-width: 100%;
    @media (min-width: 768px) {
      min-width: calc(100% + 48px);
    }
  }
}
