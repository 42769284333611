.ColorPicker {
  position: relative;
  top: 0;
  left: 0;
  @media (max-width: 500px) {
    position: static;
  }
  &__color {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 100px;
    width: 100%;
  }
  &__swatch {
    background: #fff;
    // border: 1px solid #ccc;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    border: none;
    // padding: 10px;
    width: 100%;
  }
  &__cover {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 2;
    @media (max-width: 500px) {
      position: absolute;
      width: 100%;
    }
  }
  &__popover {
    position: absolute;
    top: 40px;
    z-index: 3;
    @media (max-width: 500px) {
      top: initial;
      left: 50%;
      transform: translate(-50%, -80px);
    }
  }
  &__label {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0.3rem;
  }
}

.Colors-Selector__wrapper {
  position: relative;
}
