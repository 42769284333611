.image-button {
  border: 1px solid #e8e6e6;
  position: relative;
  top: 0;
  left: 0;
  &:hover::after {
    content: 'Select layout';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(194, 194, 194, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: #000;
  }

  img {
    width: 100%;
    height: auto;
  }
}
