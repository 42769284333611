//CHIPS STYLES SATART
$active-color: #dcfadd;
$active-text-color: #27661c;
$declined-color: #eadada;
$declined-text-color: #cf1717;
$accepted-color: #abc7ce;
$accepted-text-color: #333e9d;

// @mixin chip($color, $text-color) {
//   color: $text-color;
//   &::before {
//     content: '';
//     display: inline-block;
//     width: 10px;
//     height: 10px;
//     border-radius: 50%;
//     background-color: $text-color;
//     margin-right: 10px;
//   }
// }

// .table-status-chip {
//   display: inline-block;
//   padding: 5px 5px;
//   border-radius: 10px;
//   font-weight: 600;
//   font-size: 12px;
//   &--1,
//   &--active {
//     @include chip($active-color, $active-text-color);
//   }
//   &--2 {
//     @include chip($declined-color, $declined-text-color);
//   }
//   &--3 {
//     @include chip($accepted-color, $accepted-text-color);
//   }
//   &--4 {
//     @include chip($accepted-color, $accepted-text-color);
//   }
//   &--5,
//   &--expired,
//   &--declined {
//     @include chip($declined-color, $declined-text-color);
//   }
// }

.table-status-chip {
  color: var(--status-color);
  display: inline-block;
  padding: 5px 5px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--status-color);
    margin-right: 10px;
  }
}

// CHIPS STYLES ENDS
