.quick--links_drop-area {
  /* Fancy */
  --min-column-width: min(200px, 100%);

  display: grid;
  gap: 32px 16px;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-column-width), 1fr));
  height: auto;
  width: 100%;

  @media (max-width: 768px) {
    --min-column-width: min(145px, 100%);
    gap: 16px;
  }
}

.quick--links_empty {
  --scaleX: 1;
  --opacity: 1;
  --h: 40px;

  grid-column: -1 / 1;
  background-color: var(--bs-gray-200);
  border: 2px dashed var(--bs-gray-400);
  color: var(--bs-gray-500);
  min-height: var(--h);

  display: grid;
  place-content: center;
}
