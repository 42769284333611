.reports__wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: 768px) {
    margin-top: -36px;
  }

  &__action-wrapper {
    background-color: var(--bs-white);
    margin-top: 0 !important;
  }

  &__sticky-container {
    @media (min-width: 768px) {
      position: sticky;
      background: linear-gradient(hsla(0, 0%, 95%, 1) 92%, hsla(0, 0%, 95%, 0) 100%);
      padding-top: 0.5rem;
      isolation: isolate;
      top: 0px;
      z-index: 1;
    }
    margin-top: 1.5rem;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -20px;
  }
}

.reports__display {
  font-size: 0.75rem;
  --reports-display-py: 0.75rem 0.2rem;

  .row {
    @media (max-width: 578px) {
      flex-direction: column;
      gap: 6px;

      .col:nth-of-type(2) {
        font-weight: 600;
      }
    }
  }

  &-regular {
    padding: var(--reports-display-py);
  }

  &-header {
    padding: var(--reports-display-py);
    font-size: 0.9rem;
    font-weight: 600;
    border-bottom: 1px solid var(--bs-gray-300);
  }

  &-total {
    padding: var(--reports-display-py);
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 16px;
    background-color: var(--bs-gray-200);
    border-radius: var(--bs-border-radius);
  }

  &-net-income {
    padding: var(--reports-display-py);
    --net-color: gray;

    font-size: 1rem;
    font-weight: 600;
    color: var(--bs-white);
    background-color: var(--net-color);
    border-radius: var(--bs-border-radius);
  }

  &-small-footer {
    margin-top: 1rem;
    font-size: 0.8rem;
    text-align: center;
  }

  &-react-table {
    // Adds bottom "border" to tr (and thead's tr too)
    // only in desktop report tables
    @media (min-width: 1200px) {
      thead tr {
        position: relative;
        &::after {
          position: absolute;
          left: 0;
          bottom: -8px;
          content: "";
          width: 100%;
          height: 1.5px;
          background-color: var(--bs-gray-400);
        }
      }
      tr.lb_table-row {
        position: relative;
        &::after {
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          width: 100%;
          height: 1px;
          background-color: var(--bs-gray-200);
        }
      }
    }
  }

  &-totals {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    h6 {
      padding-left: 18px;
      margin-bottom: 8px;
    }

    div {
      display: flex;
      flex-direction: column;
    }
    table {
      font-size: 0.85rem;

      td {
        padding: 0 18px;
        font-variant: "tabular-nums";

        strong {
          font-weight: 600;
        }
      }
    }
  }
}
