.csv-input {
  &__button {
    &::file-selector-button {
      transition: all 0.3s ease;
      background-color: var(--bs-gray-300);
      color: var(--bs-black);
      font-weight: 500;
    }

    &:hover {
      &::file-selector-button {
        background-color: var(--bs-gray-300) !important;
        filter: brightness(0.8);
      }
    }
  }

  &__reset {
    appearance: none;
    background: none;
    border: none;
    font-size: 0.8rem;

    &:not(:disabled) {
      color: var(--bs-primary);
      text-decoration: underline;
    }
  }
}
