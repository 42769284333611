@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.lb-subscription {
  &__wrapper {
    background-color: var(--bs-white);
    box-shadow: var(--bs-box-shadow-sm);
  }

  &__main-container {
    @include media-breakpoint-up(lg) {
      padding: 8px;
    }

    p + p {
      margin-top: 1.4rem;
    }

    p {
      font-size: 0.85rem;
    }
  }

  &__plan-container {
    display: flex;
    height: 100%;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      min-height: 22rem;
      border: 2px solid var(--bs-primary);
      background-color: var(--bs-gray-100);
      padding: 24px;
      border-radius: var(--bs-border-radius-lg);
    }

    @include media-breakpoint-up(lg) {
      padding: 32px;
    }

    @include media-breakpoint-up(xxl) {
      padding: 42px;
    }

    &__pre {
      font-size: 1rem;
      color: var(--bs-primary);
      margin-bottom: 1.8rem;
    }

    &__plan-name {
      color: var(--bs-primary);
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0.6rem;
    }

    &__description {
      @include media-breakpoint-up(xxl) {
        max-width: 28ch;
      }

      color: var(--bs-gray-600);
    }

    &__action-group {
      margin-top: 3rem;

      @include media-breakpoint-up(sm) {
        margin-top: auto;
      }

      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
    }
  }

  &__heading {
    color: var(--bs-primary);
    font-size: 1rem;
    margin-top: 32px;
    margin-bottom: 2rem;

    @include media-breakpoint-up(xxl) {
      margin-top: 42px;
    }
  }

  &__strong {
    color: var(--bs-primary);
    font-weight: 700;
  }

  &__note {
    font-size: 0.8rem;
    color: var(--bs-gray-600);
  }

  &__feature-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 5px;

    & > li {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      font-size: 0.75rem;
      color: var(--bs-gray-700);

      button {
        display: flex;
        align-items: center;
        gap: 4px;
        appearance: none;
        border: none;
        background: transparent;
        color: inherit;
        font: inherit;
        font-weight: 500;
        color: var(--bs-primary);
        font-size: 0.8rem;

        &:hover span {
          text-decoration: underline;
          text-underline-offset: 2px;
        }

        span {
          display: inline-block;
          font-weight: 700;
        }
      }
    }
  }
}

.lb-subscription__usage {
  &__meter {
    display: flex;
    gap: 4px;
    width: 100%;

    &__invoice {
      &:first-of-type {
        border-top-left-radius: 1000px;
        border-bottom-left-radius: 1000px;
      }

      &:last-of-type {
        border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;
      }

      background-color: var(--bs-gray-200);
      height: 16px;
      width: 100%;
    }
  }
}

.lb-subscription__dev {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 8px;
  background-color: white;
  border-bottom: 1px solid gray;
  width: 100%;
  top: 0;
  left: 0;
}

.lb-subscription__popover {
  &-content {
    &::before {
      content: '';
      position: absolute;
      background-color: transparent;
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      z-index: -1;
    }

    border-radius: 8px;
    font-size: 0.75rem;
    padding: 12px;
    border: 1px solid var(--bs-gray-200);
    position: relative;
    background-color: var(--bs-white);
    box-shadow: var(--bs-box-shadow-sm);

    ul {
      padding-bottom: 0;
      margin-bottom: 0;

      li {
        &::before {
          content: '·';
          margin-right: 0.2rem;
        }
        margin-bottom: 0.5rem;
      }
    }
  }

  &-arrow {
    fill: var(--bs-gray-200);
  }

  &-trigger {
    appearance: none;
    border-radius: 0;
    text-align: inherit;
    background: none;
    box-shadow: none;
    padding: 0;
    cursor: pointer;
    border: none;
    color: var(--bs-primary);
    font: inherit;
    font-size: 0.85rem;

    span {
      font-weight: 600;
      text-decoration: underline 1.25px;
      text-underline-offset: 0.2rem;
    }
  }
}
