.lb-offcanvas__container {
  --bs-offcanvas-width: 800px;
  border-radius: revert;
  height: 100vh;
  height: 100dvh;
  isolation: isolate;

  @media (min-width: 992px) {
    &.offcanvas-end {
      border-radius: 16px 0 0 16px;
      height: 96vh;
      height: 96dvh;
      top: 2vh;
      top: 2dvh;
    }
  }
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: translateX(50%);

  @media (max-width: 992px) {
    transform: none;
    --bs-offcanvas-width: 100%;
  }
}

.offcanvas.full-size,
.offcanvas.full-size:not(.hiding) {
  transform: none;
}

.close-btn {
  position: absolute;
  top: 28px;
  right: 4vh;
  right: 4dvh;
  max-width: fit-content;
  z-index: 999;

  @media (min-width: 992px) {
    .offcanvas:not(.full-size) > & {
      right: calc(var(--bs-offcanvas-width) / 2 + 20px);
    }
  }
}

.lb-offcanvas__grid {
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    "body"
    "footer";
}

.lb-offcanvas__right,
.lb-offcanvas__left {
  height: 100%;
}

.lb-offcanvas__right {
  display: none;
  .full-size & {
    display: grid;
  }
}

#offcanvas-action-group-right,
#offcanvas-action-group-left {
  grid-area: footer;
  position: sticky;
  background: linear-gradient(hsla(0, 100%, 100%, 0) 0, hsla(0, 100%, 100%, 1) 20px);
  left: 0;
  bottom: 0;

  display: flex;
  gap: 8px;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 36px;

  & > div > button {
    margin-top: 0 !important;
  }
}
