.quick-links__container {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 2rem;
  margin-top: 1.6rem;
}

.quick-links__highlight {
  z-index: 100;
  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    background-color: var(--bs-gray-100);
    top: -8px;
    left: -8px;
    border-radius: 8px;
  }
}

.quick-links__overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 13%, 0.5);
  z-index: 99;
}

.quick-links__action-group {
  display: flex;
  position: relative;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  align-items: normal;
  margin-bottom: 1.2rem;

  // To allow the QL icons animation to look nicely
  overflow: hidden;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: start;
  }
}

.action--group_container {
  --x: 100%;
  --o: 1;
  --h: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  height: var(--h);
  opacity: var(--o);
  transition:
    transform 0.4s,
    opacity 0.1s;
  transform: translateX(var(--x));
}

.action--group_icon-wrapper {
  --stroke-color: var(--bs-primary);
  position: relative;
  flex: 0 0 fit-content;
  display: flex;
  align-self: center;
  padding: 8px;
  align-items: center;
  gap: 8px;
  font-size: 0.8rem;
  width: auto;

  svg {
    display: block;
    fill: transparent;
    stroke: var(--stroke-color);
    stroke-width: 3px;
    width: 20px;
    transition: stroke 0.2s;
  }

  :checked + & {
    svg {
      --stroke-color: var(--bs-white);
    }
  }

  &:hover {
    filter: brightness(0.85);
    svg {
      --stroke-color: var(--bs-white);
    }
  }
}

.quick-links__button {
  background-color: var(--bs-gray-400);
  border-color: var(--bs-gray-400);
  color: var(--bs-gray-700);
  flex: 0 0 auto;
  height: fit-content;

  display: flex;
  align-items: center;
  gap: 8px;

  .edit-icon {
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}

.quick-links__hidden {
  display: none;
}

.animations__breathe {
  animation: breathe 0.4s infinite both ease-in-out;
}

@keyframes breathe {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
