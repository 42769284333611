.csv {
  form#import-csv {
    margin-top: 0 !important;
  }

  &__main-container {
    box-shadow:
      var(--bs-box-shadow-sm),
      rgba(0, 0, 0, 0.3) 0 1px 3px;
    border-radius: 8px;
    padding: 48px;
    background-color: var(--bs-white);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(0, 1fr);
    gap: 64px;

    min-height: 0;
    min-width: 0;
    max-height: 100%;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  &__sticky-container {
    @media (min-width: 992px) {
      min-height: 0;
      position: sticky;
      top: 2rem;
      align-self: start;
    }
  }

  &__heading {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  &__success-heading {
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 8px;
    font-size: 1.1rem;
    color: var(--bs-success);
  }

  &__ul {
    columns: 2;
    width: 100%;
    color: var(--bs-gray-700);
    font-size: 0.9rem;
    margin-top: 1rem;
    @media (max-width: 28rem) {
      columns: 1;
    }
    li {
      &::before {
        content: "✅";
        margin-right: 0.3rem;
      }
    }
  }

  &__info-p {
    color: var(--bs-gray-700);
    font-size: 0.9rem;
  }

  &__disclaimer {
    color: var(--bs-gray-600);
    font-size: 0.75rem;
  }

  &__link {
    appearance: none;
    border: none;
    background: none;
    color: var(--bs-primary);
    text-decoration: underline;
    margin-top: auto;
    transition: color 0.3s ease;

    &:hover {
      color: var(--bs-gray-700);
    }
  }

  &__customer-list-item {
    --csv-list-spacing: 1.2rem;

    margin-top: var(--csv-list-spacing);
    margin-bottom: var(--csv-list-spacing);
    padding-left: var(--csv-list-spacing);
    padding-right: var(--csv-list-spacing);

    &:not(:last-of-type) {
      padding-bottom: var(--csv-list-spacing);
      border-bottom: 1px solid var(--bs-gray-400);
    }

    &__name {
      display: block;
      color: var(--bs-primary);
      font-weight: 500;
      font-size: 0.9rem;
      margin-bottom: 0.6rem;
    }

    &__address {
      margin-bottom: 0.4rem;
    }

    &__address,
    &__phone {
      display: flex;
      gap: 6px;
      align-items: flex-start;
      color: var(--bs-gray-700);
      font-size: 0.8rem;
    }
  }
}
