.bar-legend {
  padding-left: 0;
  display: flex;
  justify-content: center;
  list-style: none;
  max-height: 120px;
  margin-bottom: 0;

  .item {
    --legend-color: black;

    display: flex;
    padding: 4px;
    align-items: center;
    gap: 8px;
    padding: 8px 24px 8px 16px;

    .color-square {
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 100px;
      background-color: var(--legend-color);
    }

    .field-name {
      font-size: 0.8rem;
      font-weight: 600;
      color: var(--legend-color);
    }

    .value {
      font-size: 0.9rem;
      font-weight: 500;
      margin-left: auto;
      color: var(--bs-primary);
    }
  }
}

.custom-tooltip {
  background-color: var(--bs-white);
  border-radius: 8px;
  border: 1px solid var(--bs-gray-300);

  font-size: 0.8rem;

  .label {
    font-weight: 600;
    font-size: 0.9;
    padding: 8px;
    border-bottom: 1px solid var(--bs-gray-300);
    color: var(--bs-primary);
  }

  .value-container {
    list-style: none;
    padding: 8px;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
    }

    .data-pair {
      --legend-color: black;
      color: var(--legend-color);
    }

    .value {
      font-weight: 700;
    }
  }
}

.bar-chart__container {
  width: 100%;
  height: 380px;
  display: flex;
  flex-direction: column;
}

.bar-chart__arrow-container {
  display: flex;
  width: 100%;
  min-height: 48px;
  justify-content: space-between;
}

.bar-chart__arrow-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  // align-self: center;
  // transform: translateY(-100%);
  background-color: transparent;
  border: 1px solid var(--bs-gray-500);

  &:disabled {
    background-color: transparent;
    border: 1px solid var(--bs-gray-300);
    svg {
      stroke: var(--bs-gray-300);
    }
  }

  svg {
    min-width: 18px;
    min-height: 18px;
    stroke: var(--bs-gray-500);
    stroke-width: 5px;
    stroke-linecap: round;
    fill: transparent;
  }
}
