.appointments-week-view {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__row {
    display: grid;
    grid-template-columns: 1fr 18ch 11ch max-content;
    grid-template-areas: 'name time badge action';
    gap: 8px;
    align-items: center;
    justify-items: flex-end;
    text-decoration: none;
    width: 100%;
    min-height: 2rem;
    background-color: var(--bs-white);
    padding: 8px 16px;

    font-size: 0.8rem;
    font-weight: 500;
    font-variant: tabular-nums;

    .appointments-week-view__row__name {
      @media (min-width: 768px) and (max-width: 1400px) {
        max-width: 24ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--bs-gray-500);
    }

    &__action-group {
      display: flex;
      gap: 6px;
      grid-area: action;

      & > * {
        flex: 0 0 fit-content;
      }

      @media (max-width: 576px) {
        margin-left: auto;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 2fr 18ch;
      grid-template-areas:
        'badge time'
        'name action';
      gap: 16px;
      padding: 16px;
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'badge'
        'time'
        'name'
        'action';
      gap: 16px;
      padding: 16px;

      & > * {
        justify-self: flex-start;
      }
    }

    &__name {
      justify-self: flex-start;
      grid-area: name;
    }

    &__time {
      grid-area: time;
      @media (max-width: 768px) {
      }
    }
  }

  &__row-header {
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }

  &__badge {
    grid-area: badge;
    justify-self: center;

    &.pending {
      background-color: var(--bs-warning);
      color: currentColor;
    }

    &.completed {
      background-color: var(--bs-success);
      color: var(--bs-white);
    }

    &.overdue {
      background-color: var(--bs-danger);
      color: var(--bs-white);
    }

    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 1000px;
    font-size: 0.6rem;
    height: min-content;

    @media (max-width: 768px) {
      justify-self: flex-start;
    }
  }

  &__actions-no-apps {
    padding: 16px;
    border-radius: 8px;
    background-color: var(--bs-gray-300);
    color: var(--bs-gray-600);
    user-select: none;
  }

  &__actions-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__actions {
    padding: 8px;
    border-radius: 8px;
    background-color: var(--bs-white);
    font-size: 0.85rem;

    &.pending {
      border: 1px solid var(--bs-warning);
      border-left: 4px solid var(--bs-warning);
    }

    &.completed {
      border: 1px solid var(--bs-success);
      border-left: 4px solid var(--bs-success);
    }

    &.overdue {
      border: 1px solid var(--bs-danger);
      border-left: 4px solid var(--bs-danger);
    }
  }
}
