@keyframes popIn {
  0% {
    transform-origin: center;
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.link--container {
  --icon-bg: var(--bs-primary);
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: var(--bs-white);
  border-radius: 8px;
  padding: 8px;
  padding-bottom: 24px;
  height: 140px;
  text-decoration: none;
  touch-action: manipulation;
  // This animation is overwritting the dnd animation
  // animation-name: popIn;
  // animation-delay: 0.5s;
  // animation-duration: 0.3s;
  // animation-fill-mode: both;

  &[aria-disabled="false"] {
    cursor: grab;
  }

  user-select: none;

  &:hover {
    @media (min-width: 768px) {
      // To prevent the hover effect on the placeholder
      // since it looked weird while we dragged
      .link--container__icon:not(.dragging-icon) {
        transition: transform 0.2s ease-out;
        transform: translate(-50%, -20%) scale(1.1);
      }
    }
  }

  .body {
    flex: 1 0;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &.stat {
      color: var(--bs-primary);
      font-size: 1.5rem;
      font-weight: 600;

      span {
        color: var(--bs-gray-500);
        font-size: 0.85rem;
        font-weight: 400;
      }
    }
  }

  @media (max-width: 768px) {
    height: 125px;
    padding: 8px;
    background-color: var(--bs-white);
    flex-basis: 125px;

    .body {
      &.stat {
        color: var(--icon-bg);
        flex: 0 0 auto;
        font-size: 0.8rem;
        flex-direction: row;
        align-items: center;
        margin-top: auto;
        margin-bottom: 0.2rem;
        gap: 8px;

        span {
          color: var(--icon-bg);
          font-size: 0.75rem;
        }
      }

      button {
        font-size: 0.8rem;
        --bs-btn-color: var(--icon-bg);
        --bs-btn-hover-color: var(--bs-white);
        --bs-btn-bg: var(--bs-white);
        --bs-btn-border-color: transparent;
        --bs-btn-hover-border-color: var(--icon-bg);
        --bs-btn-hover-bg: var(--icon-bg) !important;
      }
    }
  }
}

.link--container.dragging {
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  .dragging-icon {
    background-color: var(--bs-gray-400);
    border-radius: 16px;
    height: 60px;
    width: 60px;

    @media (max-width: 768px) {
      margin-top: 10px;
      border-radius: 8px;
      height: 30px;
      width: 30px;
    }
  }

  .dragging-placeholder {
    min-height: var(--h);
    min-width: var(--w);
    height: var(--h);
    width: var(--w);

    background-color: var(--bs-gray-300);

    &.dragging-title {
      --w: 20%;
      --h: 24px;
      @media (max-width: 768px) {
        --h: 12px;
      }
    }

    &.dragging-sub-title {
      --w: 70%;
      --h: 24px;
      @media (max-width: 768px) {
        --h: 12px;
      }
    }
  }
}

.link--container__icon {
  background-color: var(--icon-bg);
  padding: 12px;
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -20%);
  transition: transform 0.4s ease-in;

  svg {
    width: 36px;
    height: 36px;
    fill: transparent;
    stroke: var(--bs-white);
    stroke-width: 2;
    stroke-linecap: round;
  }

  @media (max-width: 768px) {
    background-color: transparent;
    transform: translate(-50%, 5%);
    svg {
      width: 52px;
      height: 52px;
      stroke: var(--icon-bg);
      stroke-width: 2;
    }
  }
}
