.selected_products__table {
  margin-top: 10px;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  border-collapse: separate;
  border-spacing: 0 10px;
  @media (max-width: 75rem) {
    display: flex;
    flex-direction: column;
  }
  th {
    text-align: start;
    font-weight: 600;
    padding: 0 30px;
    @media (max-width: 75rem) {
      display: none;
    }
  }
  tbody td {
    background-color: white;
    border: 0;
    vertical-align: top;
    padding: 30px;
  }

  .table__footer {
    td {
      padding: 8px 0 !important;
    }

    .footer-spacer {
      @media (max-width: 75rem) {
        display: none;
      }
    }

    .add-item {
      grid-area: add-item;
    }
    .totals-labels {
      grid-area: totals-labels;
      text-align: right;
    }
    .totals {
      padding-left: 32px !important;
      grid-area: totals;
    }

    @media (max-width: 75rem) {
      --footer-areas: "";
      display: grid;
      padding: 30px 0;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: "add-item totals-labels totals";

      .totals {
        padding-left: 30px !important;
      }
    }

    @media (max-width: 50rem) {
      display: grid;
      padding: 30px 0;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "add-item add-item add-item"
        "totals-labels totals totals";
      .totals-labels {
        text-align: left;
      }
    }
  }

  tbody {
    @media (max-width: 75rem) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }
    tr {
      @media (max-width: 75rem) {
        position: relative;
        width: 100%;
        flex: 1 0;
        background-color: var(--bs-white);
        border: 1px solid var(--bs-gray-600);
        border-radius: 8px;
        display: grid;
        padding-top: 12px;
        padding-bottom: 12px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          "name name name"
          "category description description"
          "quantity amount rate"
          "actions actions actions";
      }

      @media (max-width: 30rem) {
        grid-template-areas:
          "name name name"
          "category category category"
          "description description description"
          "quantity amount amount"
          "rate rate rate"
          "actions actions actions";
      }
    }
  }
  .card {
    &__header {
      display: none;
    }
  }
  @media (max-width: 75rem) {
    td {
      padding: 8px 16px !important;
    }
    .card {
      &__header {
        display: block;
        font-size: 0.7rem;
        color: var(--bs-gray-600);
      }
      &__index {
        position: absolute;
        display: grid;
        padding: 0;
        place-content: center;
        min-width: 32px;
        min-height: 32px;
        border-radius: 0 7px 0 4px;
        background-color: var(--bs-primary);
        right: 0;
        color: var(--bs-white);
        font-size: 0.9rem;
        font-weight: 600;
        top: 0;
      }
      &__category {
        grid-area: category;
        width: auto !important;
      }
      &__name {
        grid-area: name;
        font-size: 1rem;
        font-weight: 600;
        width: auto !important;
      }
      &__amount {
        grid-area: amount;
      }
      &__quantity {
        grid-area: quantity;
      }
      &__actions {
        grid-area: actions;
        display: flex;
        justify-content: flex-end;
      }
      &__rate {
        grid-area: rate;
      }
      &__description {
        grid-area: description;
        font-size: 0.8rem;
        width: auto;
        margin-top: revert;
      }
    }
  }

  .td,
  .th {
    .table-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 2;
      max-width: 500px;
    }
  }
  .td-add {
    text-align: center;
    border-radius: 5px;
  }
}
.horizontal-overflow {
  max-width: 100vw;
  overflow: hidden;
  margin: -1rem;
  .wrapper {
    overflow-x: scroll;
    padding: 1rem;
  }
}

.row-span-2 {
  grid-column: 1/-1;
  font-size: 10px;
  margin-top: 30px;
  position: relative;
  width: 40%;
}

.td__money {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.td__money {
  text-align: end;
  &::before {
    content: "$";
  }
}

input {
  min-width: 10px;
}
textarea {
  width: 98%;
}
.selected_products__table tbody tr td {
  &:nth-child(2),
  &:nth-child(3) {
    width: 250px;
  }
  &:last-child {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.table-hover-edit {
  position: relative;
}

.table-hover-edit td:last-of-type {
  padding-right: 30px;
}

.button-icon {
  width: 40px;
  height: 40px;
  padding: 10px;
  img {
    object-fit: contain;
  }
}

.input_items_errors {
  background-color: rgb(255, 210, 210) !important;
}
