.PhoneInput {
  padding: 0.375rem 0.75rem;

  position: relative;

  &:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(0, 51, 79, 0.25);
    outline: none;

    &.is-invalid {
      box-shadow: 0 0 0 0.25rem var(--bs-box-shadow-red);
    }
    &.is-valid {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
    }
  }

  input {
    margin-left: 8px;
    border: none;
    outline: none;
  }
}

.PhoneInput {
  div.PhoneInputCountrySelectArrow {
    align-self: center;
  }
}
