.header {
  height: 40px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  &__business-name {
    font-size: 0.85rem;
    color: var(--bs-gray-900);
  }
  &__user-name {
    font-size: 0.7rem;
    font-weight: 400;
    color: var(--bs-gray-700);

    span {
      font-weight: 500;
    }
  }
}

.nav-actions {
  &.mobile {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      gap: 8px;
      padding: 16px;
      border-bottom: 1px solid var(--bs-gray-900)
    }
  }
}

.client-area {
  height: 40px;
  display: flex;
  align-items: center;
  /* justify-content: center; */

  text-decoration: none;
  font-weight: 600;
}
.client-area img {
  max-height: 100%;
}
.client-area p {
  margin-bottom: 0;
}

.min-chip {
  background-color: white;
  max-width: min-content;
  padding: 10px 20px;
  border-radius: 30px;
  white-space: nowrap;
}
