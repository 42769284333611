.icon-button {
  //   --icon-color: black;
  //   --hover-color: var(--bs-white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
  padding: 8px;

  svg {
    display: block;
  }

  &.icon-button__modal {
    width: 100%;
    justify-content: space-between;
    padding: 12px 16px;
  }
}
