/*  Mixins */
@mixin send-to-right {
  & > * {
    align-self: flex-end;
    justify-content: flex-end;
  }
}

@mixin border-y {
  position: relative;
  padding-top: var(--table-spacing);
  padding-bottom: var(--table-spacing);
  margin-top: 1rem;

  &::after {
    position: absolute;
    top: 0;
    content: "";
    width: calc(100% + calc(var(--table-spacing) * 2));
    height: 100%;
    border-bottom: var(--table-border);
    border-top: var(--table-border);
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@mixin border-bottom {
  position: relative;
  padding-bottom: var(--table-spacing);
  &::after {
    display: block;
    position: absolute;
    bottom: 0;
    content: "";
    width: calc(100% + calc(var(--table-spacing) * 2));
    border-bottom: var(--table-border);
    margin-left: calc(var(--table-spacing) * -1);
    margin-right: calc(var(--table-spacing) * -1);
  }
}

@mixin border-top {
  position: relative;
  padding-top: var(--table-spacing);
  margin-top: var(--table-spacing);
  &::after {
    display: block;
    position: absolute;
    top: 0;
    content: "";
    width: calc(100% + calc(var(--table-spacing) * 2));
    border-bottom: var(--table-border);
    margin-left: calc(var(--table-spacing) * -1);
    margin-right: calc(var(--table-spacing) * -1);
  }
}

/* Generic styles */
.accesor {
  display: none;

  &::after {
    display: none;
  }
}

.react_table {
  padding-left: 0;
  padding-right: 0;

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .anchor {
    align-items: flex-start;
  }
}

/* Expenses */
.react_table.expenses_table {
  tr {
    grid-template-areas:
      "date date"
      "attachment invoice_number"
      "category category"
      "vendor_name vendor_name"
      "amount amount"
      "expand more";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__date {
        font-size: 1rem;
        @include border-bottom;
      }

      &__more {
        @include send-to-right;
      }

      &__amount {
        font-size: 1rem;
        font-weight: 600;
        color: var(--bs-primary);
        @include border-y;
      }
    }
  }
}

/* Expense categories */
.react_table.expense_categories_table {
  tr {
    grid-template-areas:
      "name name"
      "description description"
      "more more";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__name {
        font-size: 1rem;
      }

      &__more {
        @include border-top;
      }
    }
  }
}

/* Merchants */
.react_table.merchants_table {
  tr {
    grid-template-areas:
      "name name"
      "actions actions";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__name {
        font-size: 1rem;
        @include border-bottom;
      }

      &__actions {
        @include send-to-right;
      }
    }
  }
}

/* Products */
.react_table.products_table {
  tr {
    grid-template-areas:
      "name name"
      "category category"
      "sku sku"
      "description description"
      "tax_rate units"
      "type price"
      "more more";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__name {
        font-weight: 600;
        color: var(--bs-primary);
        @include border-bottom;
      }

      &__more {
        @include send-to-right;
        @include border-top;
      }
    }
  }
}

/* Product categories */
.react_table.product_categories_table {
  tr {
    grid-template-areas:
      "name name"
      "products_services products_services"
      "more more";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__name {
        font-size: 1rem;
        @include border-bottom;
      }

      &__more {
        @include send-to-right;
      }
    }
  }
}

/* Invoices */
.react_table.invoices_table {
  tr {
    grid-template-areas:
      "invoice status"
      "customer_name customer_name"
      "created due_date"
      "balance send"
      "amount amount"
      "expand expand";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__invoice,
      &__status {
        @include border-bottom;
      }

      &__invoice {
        font-size: 1rem;
        display: flex;
        justify-content: center;

        &[aria-label="empty cell"] {
          display: none;
          &::after {
            display: none;
          }
        }
      }

      &__amount {
        font-size: 1rem;
        font-weight: 600;
        color: var(--bs-primary);
        @include border-top;
      }

      &__send {
        .badge {
          width: fit-content;
          padding: 6px 8px;
          margin-left: -6px;
        }
      }

      &__expand {
        @include send-to-right;
        @include border-top;
      }
    }
  }
}

/* Estimates */
.react_table.estimates_table {
  tr {
    grid-template-areas:
      "estimates status"
      "customer_name customer_name"
      "created expires"
      "amount amount"
      "send send";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__estimates,
      &__status {
        @include border-bottom;
      }

      &__estimates {
        font-size: 1rem;
        display: flex;
        justify-content: center;
      }

      &__status {
        justify-content: center;
      }

      &__amount {
        font-size: 1rem;
        font-weight: 600;
        color: var(--bs-primary);
        @include border-y;
      }

      &__send {
        .badge {
          width: fit-content;
          padding: 6px 8px;
        }
      }
    }
  }
}

/* Customers */
.react_table.customers_table {
  tr {
    grid-template-areas:
      "customer_name customer_name"
      "email_address email_address"
      "mobile mobile"
      "phone_number phone_number"
      "balance balance";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__customer_name {
        font-size: 1rem;
        @include border-bottom;
      }

      &__balance {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Payments */
.react_table.payments_table {
  tr {
    grid-template-areas:
      "payment_date invoice"
      "customer_name customer_name"
      "payment_method invoice_balance"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__payment_date,
      &__invoice {
        justify-content: center;
        @include border-bottom;
      }

      &__payment_date {
        font-size: 1rem;
      }

      &__amount {
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Vendors */
.react_table.vendors_table {
  tr {
    grid-template-areas:
      "name name"
      "insurance_expiration_date insurance_expiration_date"
      "actions actions";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__name {
        font-size: 1rem;
        @include border-bottom;
      }

      &__actions {
        @include send-to-right;
        @include border-top;
      }
    }
  }
}

// ===== REPORTS (should we move this? probably hehe =====

/* Profit and Loss by Customer */
.react_table.reports_pl_customer_table {
  tr {
    grid-template-areas:
      "customer customer"
      "sales expenses"
      "net_income net_income";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__customer {
        font-size: 1rem;
        @include border-bottom;
      }

      &__net_income {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Sales */
.react_table.reports_sales_table {
  tr {
    grid-template-areas:
      "invoice invoice"
      "date tax"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__invoice {
        font-size: 1rem;
        @include border-bottom;
      }

      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Sales by Customer */
.react_table.reports_sales_customer_table {
  tr {
    grid-template-areas:
      "date invoice"
      "customer tax"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Sales by Product */
.react_table.reports_sales_product_table {
  tr {
    grid-template-areas:
      "product_name product_name"
      "total_quantity total_tax"
      "total_price total_price";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__product_name {
        font-size: 1rem;
        @include border-bottom;
      }
      &__total_price {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Sales by Service */
.react_table.reports_sales_service_table {
  tr {
    grid-template-areas:
      "service_name service_name"
      "total_quantity total_tax"
      "total_price total_price";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__service_name {
        font-size: 1rem;
        @include border-bottom;
      }
      &__total_price {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Products and Services List */
.react_table.reports_sales_item_list_table {
  tr {
    grid-template-areas:
      "product_service product_service"
      "description description"
      "tax sku"
      "price price";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__product_service {
        font-size: 1rem;
        @include border-bottom;
      }
      &__price {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Expenses by Category */
.react_table.reports_expenses_category {
  tr {
    grid-template-areas:
      "category category"
      "merchant_vendor recurrent"
      "description description"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__category {
        font-size: 1rem;
        @include border-bottom;
      }
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Expenses by Invoice */
.react_table.reports_expenses_invoice {
  tr {
    grid-template-areas:
      "invoice invoice"
      "category merchant_vendor"
      "description description"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__invoice {
        font-size: 1rem;
        @include border-bottom;
      }
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Expenses by Merchant */
.react_table.reports_expenses_merchant {
  tr {
    grid-template-areas:
      "merchant_name merchant_name"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__merchant_name {
        font-size: 1rem;
      }
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Expenses by Vendor */
.react_table.reports_expenses_vendor {
  tr {
    grid-template-areas:
      "vendor_name vendor_name"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__vendor_name {
        font-size: 1rem;
      }
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Vendors */
.react_table.vendors_table {
  tr {
    grid-template-areas:
      "name name"
      "insurance_expiration_date insurance_expiration_date"
      "actions actions";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__name {
        font-size: 1rem;
        @include border-bottom;
      }

      &__actions {
        @include send-to-right;
        @include border-top;
      }
    }
  }
}

// ===== REPORTS (should we move this? probably hehe =====

/* Profit and Loss by Customer */
.react_table.reports_pl_customer_table {
  tr {
    grid-template-areas:
      "customer customer"
      "sales expenses"
      "net_income net_income";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__customer {
        font-size: 1rem;
        @include border-bottom;
      }

      &__net_income {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Sales */
.react_table.reports_sales_table {
  tr {
    grid-template-areas:
      "invoice invoice"
      "date tax"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__invoice {
        font-size: 1rem;
        @include border-bottom;
      }

      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Sales by Customer */
.react_table.reports_sales_customer_table {
  tr {
    grid-template-areas:
      "date invoice"
      "customer tax"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Sales by Product */
.react_table.reports_sales_product_table {
  tr {
    grid-template-areas:
      "product_name product_name"
      "total_quantity total_tax"
      "total_price total_price";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__product_name {
        font-size: 1rem;
        @include border-bottom;
      }
      &__total_price {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Sales by Service */
.react_table.reports_sales_service_table {
  tr {
    grid-template-areas:
      "service_name service_name"
      "total_quantity total_tax"
      "total_price total_price";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__service_name {
        font-size: 1rem;
        @include border-bottom;
      }
      &__total_price {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Products and Services List */
.react_table.reports_sales_item_list_table {
  tr {
    grid-template-areas:
      "product_service product_service"
      "description description"
      "tax sku"
      "price price";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__product_service {
        font-size: 1rem;
        @include border-bottom;
      }
      &__price {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Expenses by Category */
.react_table.reports_expenses_category {
  tr {
    grid-template-areas:
      "category category"
      "merchant_vendor recurrent"
      "description description"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__category {
        font-size: 1rem;
        @include border-bottom;
      }
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Expenses by Invoice */
.react_table.reports_expenses_invoice {
  tr {
    grid-template-areas:
      "invoice invoice"
      "category merchant_vendor"
      "description description"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__invoice {
        font-size: 1rem;
        @include border-bottom;
      }
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Expenses by Merchant */
.react_table.reports_expenses_merchant {
  tr {
    grid-template-areas:
      "merchant_name merchant_name"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__merchant_name {
        font-size: 1rem;
      }
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Expenses by Vendor */
.react_table.reports_expenses_vendor {
  tr {
    grid-template-areas:
      "vendor_name vendor_name"
      "amount amount";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__vendor_name {
        font-size: 1rem;
      }
      &__amount {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
        @include border-top;
      }
    }
  }
}

/* Checks */
.react_table.checks_table {
  padding-right: 0;
  padding-left: 0;
  tr {
    grid-template-areas: "description invoice amount actions";
    display: grid;
    grid-template-columns: 1fr 400px 150px 75px;
    margin-bottom: 10px;
    align-items: center;

    &:first-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    td {
      padding: 10px 20px;
      .edit-select__control {
        border: transparent;
        border-radius: 3px;
        width: 100%;
        &:hover {
          border: rgb(57, 57, 57) 1px solid;
        }
      }
      .table__input:hover,
      .add-select__control:hover {
        border: rgb(57, 57, 57) 1px solid;
      }
    }
    &:hover {
      .table__input,
      .edit-select__control {
        border: 1px solid #ccc;
      }
    }
  }
  tfoot tr {
    background-color: #ffffff;
  }
  .table__input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    border: transparent;
    min-height: 36px;
    border-radius: 3px;
    &:focus {
      border: initial;
    }
    &--add {
      border: 1px solid #ccc;
    }
  }
  @media (max-width: 1200px) {
    tr {
      grid-template-columns: 1fr;
      grid-template-areas: "description" "invoice" "amount" "actions";
      padding: 0;
      .accesor {
        tr {
          grid-template-columns: 1fr;
          grid-template-areas:
            "description"
            "invoice"
            "amount"
            "actions";
          padding: 0;
          .accesor {
            display: block;
            color: gray;
            font-size: 0.65rem;
            padding-bottom: 0.25rem;
          }
        }
      }
    }

    .mobile {
      &__actions {
        @include send-to-right;
        border-top: 1px solid var(--bs-gray-300);
        margin-top: 0.5rem;
        padding: 16px 20px;
      }
    }
  }
}

/* Checks Table */
.react_table.checks__all {
  tr {
    grid-template-areas:
      "state . . .  actions tray"
      "id payment_date to total total total";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__actions,
      &__tray {
        @include send-to-right;
      }
      &__total,
      &__payment_date,
      &__to,
      &__id {
        @include border-top;
      }
      &__total {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);

        & > span {
          text-align: right;
        }
      }
    }
  }
}

/* Checks Tray */
.react_table.checks__tray {
  margin-top: 0;
  tr {
    grid-template-areas:
      "to to"
      "payment payment"
      "total actions";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__payment {
        font-size: 1rem;
        @include border-bottom;
      }
      &__to {
        font-size: 1rem;
        @include border-bottom;
      }
      &__total {
        font-weight: 600;
        font-size: 1rem;
        color: var(--bs-primary);
      }
      &__actions {
        @include send-to-right;
      }
    }
  }
}

/* Vehicles table */
.react_table.vehicles_table {
  tr {
    grid-template-areas:
      "brand model"
      "license_plate year"
      "expand expand";
  }
  @media (max-width: 1200px) {
    .mobile {
      &__brand,
      &__model {
        font-weight: 600;
      }

      &__expand {
        @include border-top;
      }
    }
  }
}
