.searcher-container {
    background-color: white;
    height: 140px;
    padding: 5px;
    padding-left: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;

    .filters-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .labels-container {
            display: flex;
            flex-direction: row;
            margin-top: -10px;

            .left-label {
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 5px;
            }

            .right-label {
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 5px;
                margin-left: 143px;
            }
        }
    }
    
    .title {
        font-weight: 700;
        margin-bottom: 20px;
    }
    
    .dates-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .date-text {
            font-weight: 500;
        }

        .select-input {
            width: 150px;
            margin-right: 10px;
            height: 40px;
            border-radius: 10px;
            border: 1px solid black;
            padding: 10px;
            font-size: 14px;
        }
    }
    

    .date-input-from {
        width: 200px;
        margin: 0 10px 0 10px;
        height: 40px;
        border-radius: 10px;
        border: 1px solid black;
        padding: 10px;
    }

    .date-input-to {
        width: 200px;
        margin: 0 10px 0 10px;
        height: 40px;
        border-radius: 10px;
        border: 1px solid black;
        padding: 10px;
    }

    .buttons-container {
        // background-color: red;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        margin-right: 20px;

        .download-print-btn-container {
            display: flex;
            justify-content: center;
            align-items: center;

            .download-btn {
                background-color: #12324d;
                color: white;
                font-weight: 600;
                border: none;
                width: 60px;
                height: 45px;
                border-radius: 10px;
                margin: 5px;
                padding-left: 20px;
            }
    
            .print-btn {
                background-color: #12324d;
                color: white;
                font-weight: 600;
                border: none;
                width: 60px;
                height: 45px;
                border-radius: 10px;
                padding-left: 20px;
            }
    
        }
        .report-btn {
            background-color: #12324d;
            color: white;
            font-weight: 600;
            border: none;
            width: 125px;
            height: 40px;
            border-radius: 10px;
            font-size: 13px;
            margin-top: 5px;
        }
    }
}
