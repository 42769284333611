.bullet-list {
  padding-left: 8px;

  li::before {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;

    color: #68808d;
  }
  li {
    position: relative;
    border-radius: 13px;
    margin-top: 10px;
    font-style: normal;
    margin-left: 20px;

    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;

    list-style-position: outside;
    color: #68808d;
  }

  & li {
    list-style-position: outside;
  }
}
