.tooltip__content {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-gray-200);
  border-radius: 4px;
  color: black;
  padding: 4px 10px;
  font-weight: 500;
  font-size: 0.85rem;
}

.tooltip__arrow {
  fill: var(--bs-white);
  transform: translateY(-1px);
}
