.w-lg-auto {
  @media (min-width: 1200px) {
    width: min-content;
  }
}

.scheduler__timeslot-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;

  .btn.btn-seconday.scheduler__timeslot {
    appearance: none;
    border: none;
    border: 1px solid var(--bs-gray-300);
    border-radius: 0;
    background-color: var(--bs-gray-100);
    margin-left: 6ch;
    min-height: 2rem;

    position: relative;

    .scheduler__start-time,
    .scheduler__end-time {
      position: absolute;
      pointer-events: none;
      line-height: 0;
      left: 0;
      font-size: 0.7rem;
      font-weight: 400;
      transform: translateX(-115%);
      color: var(--bs-gray-600);
    }

    .scheduler__start-time {
      top: 0;
    }

    .scheduler__end-time {
      bottom: 0;
    }

    &:hover:not(.disabled) {
      background-color: var(--bs-gray-300);
    }

    &.disabled {
      cursor: disabled;
      background-color: var(--bs-gray-200);
    }
  }
}

.scheduler__overflow-container {
  max-height: 60vh;
  overflow-y: auto;
  padding-top: 1rem;
  scroll-padding-top: 1rem;
}

.appointments__sidebar {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 1200px) and (min-width: 992px) {
    flex-direction: row;
  }
}

.appointments__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 2.5rem;

  h2 {
    font-size: 1.6rem;
    font-weight: 600;
  }
  margin-bottom: 1.5rem;
}

.appointment-overlay__container {
  --rows: 22;

  position: absolute;
  // We substract the scheduler timestamp margin
  height: 100%;
  width: calc(100% - 6ch);
  top: 0;
  right: 0;
  padding: 0 16px 0 8px;
  pointer-events: none;

  display: grid;
  grid-template-rows: repeat(var(--rows), 1fr);
  grid-auto-columns: 1fr;
}

.appointment-overlay__item {
  --appointment-start: 1;
  --appointment-end: 2;
  --spacing: 4px;

  grid-row: var(--appointment-start) / var(--appointment-end);
  margin: var(--spacing);
  width: calc(100% - calc(var(--spacing) * 2));
  background-color: var(--bs-gray-100);
  border-radius: 8px;
  border: 1px solid var(--bs-primary);
  border-left: 4px solid var(--bs-primary);
  pointer-events: all;

  padding: var(--spacing);
  padding-left: calc(var(--spacing) * 2);
  font-size: 0.7rem;
  text-decoration: none;
  color: var(--bs-primary);
  overflow: hidden;

  &.completed__appointment {
    border: 1px solid var(--bs-success);
    border-left: 4px solid var(--bs-success);
  }
  &.pending__appointment {
    border: 1px solid var(--bs-warning);
    border-left: 4px solid var(--bs-warning);
  }
  &.overdue__appointment {
    border: 1px solid var(--bs-danger);
    border-left: 4px solid var(--bs-danger);
  }
}

// Remove extra margin when there's overlapping appointments
// .appointment-overlay__item + .appointment-overlay__item {
//   margin-left: 0;
//   width: calc(100% - calc(var(--spacing)));
// }

.new-appointment__read-only {
  ul,
  p,
  a {
    list-style: none;
    padding-left: 0;
    font-size: 0.8rem;
    font-weight: 300;
    // color: var(--bs-gray-600);
  }

  a {
    color: var(--bs-primary);
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--bs-gray-800);
    margin-bottom: 0.4rem;
  }
}

.new-appointment__header {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0.4rem;
}

.new-appointment__sub-header {
  font-weight: 300;
  font-size: 1rem !important;
}

.new-appointment__disable {
  display: none;
}

.new-appointment__status-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  width: fit-content;
  font-weight: 600;
  font-size: 0.7rem;
}

.new-appointment__status-container {
  display: flex;
  gap: 8px;
  align-items: center;

  h3 {
    font-weight: 300;
    line-height: 1.5;
  }
}

.new-appointment__duration-container {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bs-gray-100);
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;

  & > .text-field {
    flex: 0 1 auto;
    width: max-content;
    font-variant: tabular-nums;
  }

  .arrow-right {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 80%;
    border-radius: 1000px;

    padding: 8px;
    display: block;
    background-color: var(--bs-gray-200);

    & > svg {
      height: 16px;
      width: 16px;
      stroke-width: 3px;
    }
  }
}
