.react_table tbody tr.edit-table__empty-alert {
  display: grid;
  place-content: center;
  text-align: center;
  min-height: 200px;
  background-color: var(--bs-white);
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--bs-gray-300);
}

.add-check {
  background-color: var(--bs-gray-200);
  margin-bottom: -20px;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--bs-gray-300);
  border-bottom: 1px solid var(--bs-primary);
  padding: 8px 0;
  .add-check__row {
    grid-template-areas: 'description invoice amount action';
    display: grid;
    grid-template-columns: 1fr 400px 150px 75px;
    align-items: center;
    align-content: flex-end;
    .add-check__col {
      padding: 10px 20px;
      .edit-select__control {
        border: transparent;
        border-radius: 3px;
        width: 100%;
        &:hover {
          border: rgb(57, 57, 57) 1px solid;
        }
      }
      .table__input:hover,
      .add-select__control:hover {
        border: rgb(57, 57, 57) 1px solid;
      }
    }
    &:hover {
      .table__input,
      .edit-select__control {
        border: 1px solid #ccc;
      }
    }

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      grid-template-areas: 'description' 'invoice' 'amount' 'actions';
      padding: 0;
      .accesor {
        tr {
          grid-template-columns: 1fr;
          grid-template-areas:
            'description'
            'invoice'
            'amount'
            'actions';
          padding: 0;
          .accesor {
            display: block;
            color: gray;
            font-size: 0.65rem;
            padding-bottom: 0.25rem;
          }
        }
      }

      .mobile {
        &__actions {
          display: flex;
          border-top: 1px solid var(--bs-gray-300);
          margin-top: 0.5rem;
          padding: 16px 20px;
          justify-content: flex-end;
        }
      }
    }
  }
  .table__input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    border: transparent;
    min-height: 36px;
    border-radius: 3px;
    &:focus {
      border: initial;
    }
    &--add {
      border: 1px solid #ccc;
    }
  }
  div.mobile__actions {
    align-self: flex-end;
  }
}

.react_table tbody tr.total__row {
  display: grid;
  padding: 12px 0;
  align-content: baseline;
  text-align: end;
  // background-color: var(--bs-gray-300);
  color: var(--bs-primary);
  font-size: 0.8rem;
  pointer-events: none;

  .total__value {
    margin-left: 0.6rem;
    font-size: 1.1rem;
    font-weight: 600;
  }
}
