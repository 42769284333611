.card-style {
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  padding: 20px 0 20px;
  background-color: white;
  height: min-content;
  padding: 4rem;
}

@media screen and (max-width: 768px) {
  .card-style {
    padding: 1rem;
    padding-top: 2rem;
  }
}
