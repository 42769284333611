.table-legend {
  padding-left: 0;
  list-style: none;
  max-height: 120px;
  overflow: auto;
  border-radius: 8px;
  border: 1px solid var(--bs-gray-300);
  background-color: var(--bs-gray-100);
  margin-top: 15px;

  .item {
    --legend-color: black;

    display: flex;
    padding: 4px;
    align-items: center;
    gap: 8px;
    padding: 8px 24px 8px 16px;

    &:focus-visible {
      background-color: red;
    }

    &:nth-of-type(2n) {
      background-color: var(--bs-gray-200);
    }

    .color-square {
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 100px;
      background-color: var(--legend-color);
    }

    .field-name {
      font-size: 0.8rem;
      font-weight: 600;
      color: var(--legend-color);
    }

    .value {
      font-size: 0.9rem;
      font-weight: 500;
      margin-left: auto;
      color: var(--bs-primary);
    }
  }
}

.table__overflow-container {
  max-height: calc(100% - 4.5rem);
  height: 100%;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid var(--bs-gray-300);
}

.table__container {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: row;
  }

  .item {
    display: grid;
    grid-template-columns: 1fr minmax(16ch, 30%);
    justify-content: space-between;

    padding: 16px;
    padding-right: 24px;
    background-color: var(--bs-gray-100);

    &:nth-of-type(2n) {
      background-color: var(--bs-gray-200);
    }

    font-size: 0.8rem;
    .left {
      justify-self: start;
    }
    .right {
      justify-self: end;
    }
    &.completed {
      border-left: 5px solid var(--bs-success);
    }
    &.pending {
      border-left: 5px solid var(--bs-warning);
    }
    &.overdue {
      border-left: 5px solid var(--bs-danger);
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      min-width: 16rem;
      gap: 32px;

      &.completed {
        border-left: revert;
        border-top: 5px solid var(--bs-success);
      }
      &.pending {
        border-left: revert;
        border-top: 5px solid var(--bs-warning);
      }
      &.overdue {
        border-left: revert;
        border-top: 5px solid var(--bs-danger);
      }
    }
  }
}

.table-widget__status-badge {
  transform: translateY(-2px);
  margin-bottom: 4px;
}

.appointment__data-list {
  margin: 0;
  dt,
  dd {
    display: inline;
    margin: 0;
  }
}

.appointment__control-group {
  display: flex;
  gap: 8px;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: auto;
  }
}
