.header-btn {
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 5px 10px 4px 10px;
  }
}

.btn.disabled.lb-btn-disabled {
  position: relative;

  &:hover {
    background-color: var(--bs-btn-disabled-bg);
    color: var(--bs-btn-disabled-color);
    border: solid var(--bs-border-width) var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    pointer-events: all;
  }
}
