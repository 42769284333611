.crop-container {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  border: 1px solid var(--bs-gray-200);
  overflow: hidden;
  min-width: 300px;
  flex-basis: 300px;

  // background:
  //   linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 0,
  //   linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 100%,
  //   linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 0,
  //   linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 100%,
  //   linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 0 0,
  //   linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 100% 0,
  //   linear-gradient(to top, #cbd4e1 8px, transparent 8px) 0 100%,
  //   linear-gradient(to top, #cbd4e1 8px, transparent 8px) 100% 100%;

  // background-repeat: no-repeat;
  // background-size: 70px 70px;
}
.reactEasyCrop_CropArea {
  color: var(--bs-primary) !important;
  opacity: 0.85;
}

.crop-modal__container {
  display: flex;
  gap: 32px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.cropper__validation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--bs-border-color);
  pointer-events: none;

  &.is-invalid {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) top
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    &:focus-within {
      border-color: var(--bs-form-invalid-border-color);
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
    }
  }
  &.is-valid {
    border-color: var(--bs-form-valid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) top
      calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    &:focus-within {
      border-color: var(--bs-form-valid-border-color);
      box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
    }
  }
}

.image-crop__is-hovering {
  background-color: var(--bs-gray-200);
}

.image-crop__delete-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: hsla(0, 100, 100, 0.9);

  // &::after {
  //   content: 'Your current image will be deleted!';
  //   font-size: 0.9;
  //   font-weight: 500;
  //   position: absolute;
  //   width: 100%;
  //   top: 50%;
  //   left: 0;
  //   transform: translateY(-50%);
  //   padding: 32px;
  //   text-align: center;
  // }
}
