.File {
  &__wrapper {
    width: 100%;
    height: 13.2rem;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    margin-top: 0.5rem;
    border: 2px dashed #ccc;
  }
  &__label {
    &.drag-active {
      background-color: #b0b0b0;
      > * {
        pointer-events: none;
      }
    }
  }
  &__preview {
    position: relative;
    top: 0;
    left: 0;

    p {
      visibility: hidden;
      background-color: rgba(255, 255, 255, 0.767);
      margin: 20px;
      font-size: 0.75rem;
      padding: 10px;
      text-align: center;
      border-radius: 5px;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    &:hover {
      p {
        visibility: initial;
      }
    }
    > * {
      position: relative;
      top: 0;
      left: 0;
    }
    img {
      border-radius: 5px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: contain;
      object-position: center;
    }
  }
}
