.help-tooltip {
    --ht-trigger-size: 28px;
    &__content {
        background-color: white;
        padding: 16px;
        z-index: 10001;
        max-width: 320px;
        border: 1px solid rgba(var(--bs-info-rgb), 0.5);
        border-radius: 8px;
        font-size: 0.8rem;
        line-height: 150%;
    }
    &__arrow {
        fill: rgba(var(--bs-info-rgb), 0.5);
    }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(var(--bs-info-rgb), 0.1);
        height: var(--ht-trigger-size);
        width: var(--ht-trigger-size);
        border-radius: 100px;
    }
}
