.btn-primary,
.btn-secondary,
.btn-danger,
.btn-light,
.btn-success {
  font-weight: 600 !important;
  text-decoration: none !important;
}
.decoration-none {
  text-decoration: none !important;
}
$primary: #00334f;

$btn-hover-bg-tint-amount: 50%;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;
