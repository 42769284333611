.invoice-balance__container {
  display: grid;
  grid-auto-columns: fit-content;
  grid-auto-flow: column;
  width: 100%;
  margin-top: 1rem;
  padding: 0;
  border-radius: 8px;
  background-color: hsl(150, 0%, 98%);
}

.invoice-balance__entry {
  min-width: 20%;
  padding: 8px 10px;
  appearance: none;
  background: none;
  border: none;
  border-radius: 4px;
  text-align: left;

  transition: background 350ms ease-in;

  & > span {
    display: block;
  }

  & > span.lb-modal__label {
    font-size: 0.7rem;
    color: var(--bs-gray-900);

    &.highlight {
      font-weight: 700;
    }
  }

  & > span.modal__value {
    font-size: 0.8rem;
    font-variant: "tabular-nums";

    &.highlight {
      --balance-highlight-color: black;
      color: var(--balance-highlight-color);
      font-weight: 700;
    }
  }

  &:hover {
    background-color: hsl(150, 0%, 92%);

    transition: background 200ms ease-out;
  }
}
