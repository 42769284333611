// Esto es para sobreescribir estilos de BS
.btn-check + .btn-outline:hover {
  color: white;
  background-color: var(--bs-btn-hover-bg) !important;
}

// Esto puede que haya quedado obsoleto, estaba probando crear
// "themes" segun la documentación pero o bien estuve haciendo
// algo mal o estan ya sobre-escritos los estilos y no es posible
// crear el theme y heredar el resto de los estados

// .btn-outline-blue {
//   border-color: var(--bs-blue) !important;
//   &:hover {
//     background-color: var(--bs-light-blue) !important;
//   }
// }
// .btn-outline-indigo {
//   border-color: var(--bs-indigo) !important;
//   &:hover {
//     background-color: var(--bs-light-indigo) !important;
//   }
// }
// .btn-outline-purple {
//   border-color: var(--bs-purple) !important;
//   &:hover {
//     background-color: var(--bs-light-purple) !important;
//   }
// }
// .btn-outline-pink {
//   border-color: var(--bs-pink) !important;
//   &:hover {
//     background-color: var(--bs-light-pink) !important;
//   }
// }
// .btn-outline-red {
//   border-color: var(--bs-red) !important;
//   &:hover {
//     background-color: var(--bs-light-red) !important;
//   }
// }
// .btn-outline-orange {
//   border-color: var(--bs-orange) !important;
//   &:hover {
//     background-color: var(--bs-light-orange) !important;
//   }
// }
// .btn-outline-yellow {
//   border-color: var(--bs-yellow) !important;
//   &:hover {
//     background-color: var(--bs-light-yellow) !important;
//   }
// }
// .btn-outline-green {
//   border-color: var(--bs-green) !important;
//   &:hover {
//     background-color: var(--bs-light-green) !important;
//   }
// }

// .btn-outline-teal {
//   border-color: var(--bs-teal) !important;
//   &:hover {
//     background-color: var(--bs-light-teal) !important;
//   }
// }
// .btn-outline-cyan {
//   border-color: var(--bs-cyan) !important;
//   &:hover {
//     background-color: var(--bs-light-cyan) !important;
//   }
// }

// .chip {
//   &:focus-visible {
//     outline: 0;
//     border-color: #00293f;
//     box-shadow: 0 0 0 0.25rem rgba(38, 82, 105, 0.5);
//   }
// }

// .chip-input {
//   opacity: 0;
//   position: absolute;
// }
