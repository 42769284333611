.step-list {
  &,
  & > * {
    list-style: none;
    color: #68808d;
  }
  .step::before {
    font-weight: 500;
    font-size: 16px;
    color: #68808d;
  }
  .step::after {
    background-image: url('./../../../img/011-check.svg');
    background-size: contain;
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 23px;
    left: 20px;
  }
  .step {
    background: #f1f1f1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 50px;
    margin-top: 10px;
    border-radius: 13px;
    font-weight: 400;
    font-size: 14px;

    color: #68808d;
  }
  p + p {
    margin-top: 10px;
  }
  a {
    /* font-weight: 600; */
    color: #2582ff;
    cursor: pointer;
  }
}
