// @function tint($color, $percentage) {
//   @return mix(#fff, $color, $percentage);
// }

// @function shade($color, $percentage) {
//   @return mix(#000, $color, $percentage);
// }

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.noticiation-popover__dot {
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  min-width: 3ch;
  padding: 6px 8px;
  background-color: $danger;
  color: white;
  font-size: 0.65rem;
  font-weight: 600;
  font-variant: 'tabular-nums';
  line-height: 1;
  text-align: center;
}

.notification-popover__empty {
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -10%);
  color: $gray-500;
}

.notification-popover {
  &__container {
    display: flex;
    width: 360px;
    max-width: 100vw;
    min-height: 250px;
    max-height: 450px;
    background-color: var(--bs-white);
    border-radius: 8px;
    overflow-y: auto;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    @media (max-width: 768px) {
      height: var(--radix-popper-available-height);
      width: 100vw;
      max-height: revert;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 0;

    h4 {
      display: inline-block;
    }
  }

  &__delete-all {
    padding: 0;
    border: none;
    background: transparent;
    text-decoration: underline;
    color: var(--bs-gray-700);
    font-size: 0.75rem;
  }

  &__arrow {
    fill: var(--bs-white);
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    user-select: none;
    text-decoration: none;

    p {
      margin-top: 8px;
      font-size: 0.85rem;
    }

    &.info {
      border-radius: 8px;
      border: 1px solid $info;
      border-left: 4px solid $info;
      background: lighten($info, 47);
    }
    &.success {
      border-radius: 8px;
      border: 1px solid $success;
      border-left: 4px solid $success;
      background: lighten($success, 64);
    }
    &.warning {
      border-radius: 8px;
      border: 1px solid $warning;
      border-left: 4px solid $warning;
      background: lighten($warning, 47);
    }
    &.danger {
      border-radius: 8px;
      border: 1px solid $danger;
      border-left: 4px solid $danger;
      background: lighten($danger, 44);
    }
  }

  &__item-badge {
    display: inline-block;
    padding: 2px 4px;
    border-radius: 4px;
    color: var(--bs-white);
    font-size: 0.65rem;
    font-weight: 600;
    letter-spacing: 0.05rem !important;
    text-transform: uppercase;
    margin-right: 4px;
    &.info {
      background-color: $info;
    }
    &.success {
      background-color: $success;
    }
    &.warning {
      background-color: $warning;
    }
    &.danger {
      background-color: $danger;
    }
  }

  &__item-date {
    font-size: 0.7rem;
    font-weight: 500;
    font-variant: 'tabular-nums';
    color: hsl(0, 0%, 33%);
  }
}
