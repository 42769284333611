////////
h3 {
  margin-bottom: 0;
  font-size: clamp(16px, 2vw, 18px) !important;
}

.lb-label {
  font-size: 12px;
  font-weight: 300;
  color: #838383;
  line-height: 1.5;
}
.lb-data {
  //   font-size: 14px;
  &--light {
    color: #b1b1b1;
    font-weight: 400;
  }
  &--bold {
    font-weight: 600;
  }
}
.lb-field {
  margin-top: 5px;
  margin-bottom: 5px;
}

.lb-field--img {
  display: grid;
  grid-template-columns: 20px auto;
  column-gap: 10px;
  img {
    grid-area: 1/1/3/2;
    justify-self: end;
    align-self: end;
  }
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("../font/montserrat-variable-normal.woff2") format("woff2");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("../font/montserrat-variable-italic.woff2") format("woff2");
}

.numbers {
  font-variant: tabular-nums;
  -moz-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum" color #333;
  font-family: "Montserrat", sans-serif;
}
