.itemsTable {
  tr {
    vertical-align: top;
  }
  .description-xs {
    width: 60%;
  }
}
.description-xs {
  font-size: 11px;
}
.no-data {
  vertical-align: center;
  text-align: center;
}
