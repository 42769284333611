.linkbtn-container {
    margin: 10px 0 10px 0;
}

.button-container {
    // background-color: red;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;

}

.list-button {
    display: flex;
    width: 100%;
    background-color: transparent;
    border: none;
    color: rgb(59, 128, 247);
    font-weight: 600;
}

.underline {
    border: 0.5px solid rgb(223, 223, 223);
}

.greater-than-icon {
    color: rgb(59, 128, 247);
}