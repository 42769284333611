/* 
  Icon styles (should *really* build a flexible icon component, soon™)
*/
.calendar__navigation-icon {
  --rotation: '0deg';

  display: block;

  transform: rotate(var(--rotation));
}

/* 
    Calendar styles, will move to a new file (soon™)
  */

.react-calendar {
  font-size: 0.9rem;
  max-width: 25rem;
  min-width: 25rem;
  color: var(--bs-primary);

  @media (max-width: 576px) {
    font-size: 0.75rem;
    width: 100%;
    min-width: 320px;
  }
}

.react-calendar__navigation {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
  font-weight: 700;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  appearance: none;
  background: none;
  border: none;

  padding: 8px 4px;
  border-radius: 8px;

  &:hover {
    background-color: var(--bs-gray-300);
  }
}

.react-calendar__navigation__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
}

.react-calendar__tile {
  position: relative;
  appearance: none;
  background: none;
  border: none;

  margin-top: 8px;

  &:hover {
    & abbr {
      background-color: var(--bs-gray-300);
      border: 2px solid var(--bs-primary);
    }
  }

  & > abbr {
    display: inline-block;
    background-color: var(--bs-white);
    min-width: 4ch;
    padding: 6px 8px;
    border-radius: 8px;
    border: 2px solid transparent;
    color: var(--bs-primary);
  }
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.react-calendar__weekend {
  & > abbr {
    color: var(--bs-gray-500);
  }
}

.react-calendar__has-appointment {
  & > abbr {
    color: var(--bs-blue);
  }
  &::after {
    position: absolute;
    content: '·';
    font-weight: 700;
    color: var(--bs-blue);
    bottom: -0.19rem;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 576px) {
      bottom: -0.1rem;
    }
  }
}

.react-calendar__today {
  color: var(--bs-indigo);

  & > abbr {
    border: 2px solid var(--bs-primary);
  }
}

.react-calendar__selected {
  &:not(:active) {
    & > abbr {
      font-weight: 700;
      background-color: var(--bs-primary);
      color: var(--bs-white);
      border: 2px solid var(--bs-primary);
    }
    &::after {
      color: var(--bs-white);
    }
  }

  &:hover:not(active) {
    filter: brightness(1.4);
  }
}

.react-calendar__tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .tooltip-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .tooltip__calendar-container {
    display: flex;
    max-width: 100px;
    overflow: hidden;
    flex-direction: column;
  }

  .tooltip__calendar-content {
    display: block;
  }
}
