// @import '../../App.scss';
.pac-container {
  // Please no judging otherwise it broke when placed on the modal
  z-index: 1046;
  font-family: "Montserrat", sans-serif;

  .pac-item,
  .pac-matched,
  .pac-item-query {
    font-size: 1rem;
  }

  .pac-item {
    padding: 8px;
    gap: 32px;
  }
}

textarea.form-control {
  min-height: 7.7rem;
}

.time-picker__input-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 0px 0 0px 8px;

  position: relative;

  &:focus-within {
    // @extend.form-control:focus;
    box-shadow: 0 0 0 0.25rem rgba(0, 51, 79, 0.25);
    outline: none;

    &.is-invalid {
      box-shadow: 0 0 0 0.25rem var(--bs-box-shadow-red);
    }
    &.is-valid {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
    }
  }

  input {
    flex: 0 0 auto;
    border: none;
    width: 3ch;
    text-align: center;
    outline: none;
  }
}

.time-picker__selector {
  border-radius: 4px;
  width: 280px;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-gray-200);
  z-index: 999;

  .time-selector__container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-left: 12px;

    height: 250px;
  }

  .faux-select__overflow-wrapper {
    flex: 1 0 auto;
    width: auto;
    height: 100%;
    padding-right: 18px;
    padding-left: 4px;
    padding-top: 12px;
    overflow: auto;
    scroll-padding-top: 12px;

    &:not(:last-of-type) {
      border-right: 1px solid var(--bs-gray-200);
    }
  }

  .faux-select__list {
    padding: 0;
    height: 680px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .faux-select__item {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      font-size: 0.85rem;
      border-radius: 8px;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: var(--bs-gray-700);
        color: white;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 51, 79, 0.25);
        outline: none;
      }
    }
  }

  .focused {
    background-color: var(--bs-gray-200);
  }

  .selected {
    background-color: var(--bs-primary);
    color: var(--bs-white);
  }

  .time-selector__action-group {
    border-top: 1px solid var(--bs-gray-200);
    padding: 16px;
  }
}

.form-two {
  display: grid;
  grid-template-columns: 1fr;

  // grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  .input-wide {
    grid-column: 1/-1;
  }
  .input-high {
    grid-row: span 2;
  }
}

@media (min-width: 768px) {
  .form-two {
    grid-template-columns: 1fr 1fr;
  }
}

.flex__form-wrapper {
  padding: 0.5rem;
  @media screen and (min-width: 768px) {
    padding: 0;
  }
  & > h5:first-of-type {
    margin-top: 0 !important;
  }

  .form-two {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    & > div {
      grid-column: span 12;

      @media (min-width: 992px) {
        &.flex__span-3 {
          grid-column: span 3;
        }

        &.flex__span-2 {
          grid-column: span 2;
        }

        &.flex__span-5 {
          grid-column: span 5;
        }
      }

      @media (min-width: 768px) {
        grid-column: span 6;

        &.flex__row-span-2 {
          grid-row: span 2;
        }
        &.flex__row-span-3 {
          grid-row: span 3;
        }

        &.flex__span-4 {
          grid-column: span 4;
        }

        &.flex__span-12 {
          grid-column: span 12;
        }

        &.flex_first-half {
          grid-column: 1 / 7;
        }
        &.flex_second-half {
          grid-column: 7 / 13;
        }

        &.flex_top {
          grid-row: 1 / span 3;
        }
      }
    }
  }
}

.sticky__submit-container {
  padding: 0.5rem;
  @media screen and (min-width: 768px) {
    padding: 0;
  }
  background-color: hsla(0, 0%, 95%, 0.4);
  backdrop-filter: blur(2px);

  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  & > div > button {
    margin-top: 0 !important;
  }

  @media (min-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.is_invalid .lb_select__control {
  border-color: var(--bs-danger);
}

div.form-two + h5.form__group-header {
  margin-top: 2rem !important;
}

.service-info__container {
  border: 1px solid var(--bs-gray-400);
  border-radius: 8px;
  background-color: var(--bs-white);
  padding-inline: 18px;
  margin-top: 1.5rem;
}

.service-info__toggle {
  text-align: left !important;
  appearance: none;
  border: none;
  background: none;
}

.customer-info__container {
  border: 1px solid var(--bs-gray-400);
  background-color: white;
  font-size: 0.7rem;

  .customer-info__label {
    font-weight: 500;
    font-size: 0.75rem;
    margin-bottom: 0.4rem;
  }

  .customer-label__container {
    overflow: hidden;
  }
}
