:root {
  --sky-blue: #3acbe9;
  --c-greenn: #40a54d;
  --pink: #fe8989;
}
.color-sky-blue {
  color: var(--sky-blue);
}
.color-c-green {
  color: var(--c-greenn);
}
.color-pink {
  color: var(--pink);
}

.activity-table {
  margin-right: -30px;
  margin-left: -30px;
  color: rgb(63, 63, 63);
  tr {
    td:first-child,
    th:first-child {
      padding-left: 30px;
    }
  }
  td {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    &:last-child {
      text-align: end;
      padding-right: 30px;
      font-weight: 500;
    }
  }
  th {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
  }
  tr:first-child td {
    padding-top: 15px;
  }
  tfoot {
    font-weight: 500;
    font-size: 16px;
  }
  tr.border-bottom {
    td {
      padding-bottom: 1rem;
    }

    & + tr td {
      padding-top: 1rem;
    }
  }

  tfoot {
    td {
      padding-top: 1rem;
      margin-top: 1rem;
    }
  }
}
