.custom-label {
  .sub {
    font-size: clamp(0.8rem, 2vw, 0.8rem);
    fill: var(--bs-gray-800);
  }
  .main {
    font-size: clamp(1rem, 3vw, 1.4rem);
    font-weight: 700;
    fill: var(--bs-primary);
    color: var(--legend-color);
  }
}
