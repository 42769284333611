.assets__form-wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 28px;
  border-radius: var(--bs-border-radius);
  background-color: var(--bs-white);
  box-shadow: var(--bs-box-shadow-sm);
}

.assets__col {
  font-variant: tabular-nums;
}

.assets__row {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--bs-gray-200);
}

.assets__table {
  font-size: 0.8rem;
  &-header {
    font-weight: 600;
    border-bottom: 1px solid var(--bs-gray-500);
    padding-bottom: 8px;
  }
}

.mileage__table {
  @media (min-width: 1200px) {
    background-color: #f9f9f9;
  }

  &-header {
    font-weight: 600;
    margin-bottom: 0.4rem;
    padding-bottom: 0.2rem;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  &-current-year {
    border: 1px solid var(--bs-teal);
    border-radius: 4px;
  }

  &-col {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }

  &-row:not(:last-of-type) {
    border-bottom: 1px solid var(--bs-gray-300);
  }
  @media (max-width: 1200px) {
    grid-column: 1 / -1;

    &-container {
      background-color: #f9f9f9 !important;
    }

    &-row,
    &-current-year {
      display: grid;
      grid-template-areas:
        "year total"
        "start end";

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
    &-col-year {
      grid-area: year;
      &::before {
        content: "Year";
      }
    }
    &-col-start {
      grid-area: start;
      &::before {
        content: "Start";
      }
    }
    &-col-end {
      grid-area: end;
      &::before {
        content: "End";
      }
    }
    &-col-total {
      grid-area: total;
      &::before {
        content: "Total";
      }
    }

    &-col-year,
    &-col-start,
    &-col-end,
    &-col-total {
      &::before {
        display: block;
        font-size: 0.7rem;
      }
    }
  }
}

.vehicles {
  &__list {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.85rem;
      padding: 12px 2px;

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--bs-gray-300);
      }
    }
    &-name {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      padding-left: 1px;
    }
    &-year {
      font-size: 0.7rem;
      font-style: italic;
      color: var(--bs-gray-600);
    }
    &-license {
      display: block;
      font-weight: 500;
      font-size: 0.65rem;
      padding: 2px 5px;
      border-radius: 4px;
      background-color: var(--bs-gray-200);
    }
  }
}
