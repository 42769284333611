.contact-form__message-preview {
  &__message {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-500);
    p {
      font-size: 0.85rem;
      color: var(--bs-gray-800);
    }
  }

  &__title {
    font-size: 1.1rem;
  }
}
