/* RESET */
.noscroll {
  overflow: hidden;
}

.left-side {
  /* GENERAL SELECTORS */
  hr {
    margin: 1rem 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: inherit;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-top: 1px solid;
    opacity: 0.25;
  }

  // textarea {
  //   resize: none;
  //   width: 98%;
  //   border: 0;
  //   border: 1px solid black;
  //   margin: 1px;
  // }
  input {
    width: 98%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 12px;
    border: 0;
    border: 1px solid black;
    padding: 10px;
    margin: 1px;
    margin-bottom: 15px;
  }

  // table {
  //   width: 100%;
  //   border-collapse: collapse;
  // }
  table * {
    font-size: 12px;
  }

  td {
    line-height: 1.2;
    vertical-align: top;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  tr:first-child td,
  tr:last-child td {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  td + td {
    padding-left: 20px;
  }

  th {
    padding-top: 10px;
    text-align: start;
    font-weight: 600;
    border-bottom: 1px solid black;
    line-height: 3;
  }

  th + th {
    padding-left: 20px;
  }

  tfoot tr td {
    border-top: 1px solid black;
    padding-bottom: 10px;
    padding-top: 15px;
  }

  tbody {
    /* max-height: 300px; */
    overflow: scroll;
    max-height: min-content;
  }

  tfoot {
    position: sticky;
    bottom: 0;
    background-color: white;
  }

  thead tr th {
    position: sticky;
    top: 0;
    background-color: white;
  }

  td:last-child {
    text-align: end;
  }

  /* AUX */
  .d-none {
    display: none !important;
  }

  .float-end {
    float: right;
  }

  .ml-auto {
    margin-left: auto;
  }

  .lh-0 {
    line-height: 0;
  }

  .justify-content-end {
    justify-content: end;
  }

  .mt-5 {
    margin-top: 30px;
  }

  .w-100 {
    width: 100%;
  }

  .d-mobile {
    display: none;
  }

  .text-aling-right {
    text-align: right;
  }

  .left-side {
    max-height: 100%;
    overflow-y: auto;
  }
}

$active-color: #dcfadd;
$active-text-color: #1a4a11;
$declined-color: #d49494;
$declined-text-color: #cf1717;
$accepted-color: #abc7ce;
$accepted-text-color: #323a85;

@mixin chip($color, $text-color, $content) {
  background-color: $color;
  color: $text-color;

  &::after {
    content: $content;
  }
}

.lb-modal {
  &__chip {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 6px;
    font-weight: 600;
    margin-left: 10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--estimate {
      &--1 {
        @include chip($active-color, $active-text-color, 'Active');
      }

      &--2 {
        @include chip($declined-color, $declined-text-color, 'Declined');
      }

      &--3 {
        @include chip($accepted-color, $accepted-text-color, 'Accepted');
      }

      &--4 {
        @include chip($accepted-color, $accepted-text-color, 'Converted');
      }

      &--5 {
        @include chip($declined-color, $declined-text-color, 'Expired');
      }
    }

    &--invoice {
      &--3 {
        @include chip($active-color, $active-text-color, 'Paid');
      }

      &--2 {
        @include chip($accepted-color, $accepted-text-color, 'Partial paid');
      }

      &--1 {
        @include chip($declined-color, $declined-text-color, 'Unpaid');
      }
    }
  }

  &__title {
    font-size: 23px;
    font-weight: 500;
    line-height: 2;
    display: inline-block;

    span {
      font-weight: 200;
    }
  }

  &__label {
    font-size: 12px;
    font-weight: 300;
    color: #585858;
    line-height: 2;
  }

  &__data {
    font-size: 14px;

    &--light {
      color: #b1b1b1;
      font-weight: 400;
    }

    &--bold {
      font-weight: 600;
    }
  }

  &__price {
    font-size: 23px;
    font-weight: 300;
  }

  &__data {
    &--bold {
      font-weight: 600;
    }

    &--mid {
      font-size: 15px;
    }
  }

  &__field {
    & + & {
      margin-left: 15px;
    }
  }
}

/* ESTIMATES MODAL ELEMENTS STYLE */
.c-estimates-modal {
  display: grid;
  grid-template-columns: 1fr 420px;
  grid-template-rows: 1fr min-content;
  column-gap: 50px;
  row-gap: 25px;

  .right-side {
    overflow: hidden;
    overflow-y: auto;
    margin-top: 60px;
    grid-row: 1/-1;
    grid-column: 2/-1;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  #previewContainer {
    overflow: hidden;
    min-height: calc((95vh - 120px) * 0.85);
  }

  /* BUTTONS START */
  .button {
    border: 0;
    border-radius: 10px;
    min-width: 50px;
    padding: 10px;
    color: white;
    background-color: #01334f;
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    display: block;
  }

  .button ~ .button {
    margin-left: 7px;
  }

  .button img {
    width: 50%;
    margin: 0 auto;
  }

  .button:hover {
    background-color: rgb(54, 131, 182);
  }

  .button--secondary {
    background-color: transparent;
    color: black;
    font-weight: bold;
    border: 2px solid black;
  }

  .button--secondary:hover {
    background-color: rgb(37, 37, 37);
    color: white;
  }

  .button--delete {
    background-color: #cf1717;
  }

  .button--delete:hover {
    background-color: #ce5c5c;
  }

  .sqr-btn {
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
  }

  .half-sqr-btn {
    aspect-ratio: 2/1;
  }

  /* BUTTONS END */

  /* SEND MESSAGE STYLES TAB STEP 1*/
  /* SEND MESSAGES VISIBILITY CONTROL */
  .send-message,
  .send-whatsapp,
  .send-mail {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    will-change: max-height, opacity, margin;
    transition:
      max-height 1s cubic-bezier(0.465, 0.183, 0.153, 0.946),
      opacity 1s cubic-bezier(0.465, 0.183, 0.153, 0.946),
      margin 1s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  }

  .send-message,
  .send-whatsapp,
  .send-mail {
    max-height: none;
    opacity: 100%;
    pointer-events: all;
    margin-top: 10px;
  }

  .d-hide-while-messages {
    will-change: max-height, opacity, margin;
    transition:
      max-height 1s cubic-bezier(0.465, 0.183, 0.153, 0.946),
      opacity 1s cubic-bezier(0.465, 0.183, 0.153, 0.946),
      margin 1s cubic-bezier(0.465, 0.183, 0.153, 0.946);
    max-height: 500px;
  }

  .form-visible {
    .d-hide-while-messages {
      max-height: 0;
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0;
      overflow: hidden;
      pointer-events: none;
    }

    .d-none-while-messages {
      display: none !important;
      pointer-events: none;
    }

    .margin-while-messages {
      will-change: margin;
      transition: margin 1s cubic-bezier(0.465, 0.183, 0.153, 0.946);
      margin-top: 20px;
    }

    #back {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /* SEND MESSAGE STYLES MESSAGE SENT*/
  #back,
  #new {
    display: none;
  }

  :host([sendmessage]) #back,
  :host([sendmessage][messagesent]) #back,
  :host([sendmessage][messagesent]) #new,
  :host([sendemail]) #back,
  :host([sendemail][messagesent]) #back,
  :host([sendemail][messagesent]) #new,
  :host([sendwhatsapp]) #back,
  :host([sendwhatsapp][messagesent]) #back,
  :host([sendwhatsapp][messagesent]) #new {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sended textarea,
  .sended input {
    border: 0;
    padding-left: 0;
    pointer-events: none;
    font-size: 14px;
  }

  .sended .submit,
  .sended .submit:hover {
    background-color: #44be57;
    pointer-events: none;
  }

  .sended #message {
    position: relative;
    top: 0;
    left: 0;
  }

  .sended #message::after {
    position: absolute;
    top: -2.5px;
    left: -2.5px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #44be57;
  }

  .tables-container {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    max-width: 100%;
  }

  .customer-info {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .customer-info .lb-modal__field:first-child {
    width: auto;
    overflow: auto;
  }

  .align-right {
    text-align: right;
  }

  @media (max-width: 991px) {
    .left-side {
      overflow-y: initial;
    }

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto calc((100vw - 60px) * 1056 / 816) auto;
    gap: 20px;

    #preview {
      display: none;
    }

    #previewContainer {
      min-height: auto;
    }

    .bottom-side-left {
      padding-bottom: 50px;
    }

    .bottom-side-right {
      grid-row: 2;
    }

    .bottom-mobile-fix {
      height: 30px;
      width: 30px;
    }

    .flex-wrap-md {
      flex-direction: column;
      align-items: start !important;
    }

    .ml-auto {
      margin-left: 0;
      margin-top: 20px;
    }

    .m-sm-0 {
      margin-left: 0 !important;
    }

    .right-side {
      margin-top: 0;
      grid-row: 3/-1;
      grid-column: 1/-1;
    }

    .justify-content-sm-start {
      justify-content: start;
    }

    .d-mobile {
      display: block;
    }

    :host([invoice]) .tables-container {
      display: block;
    }

    .lb-modal__field + .lb-modal__field {
      margin-left: 0;
    }

    .button ~ .button {
      margin-left: 0;
    }
  }

  #estimatesCustomerEmail {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }

  .table-payments {
    width: 100%;
  }

  #estimatesCustomerEmail:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }

  .tableWrap {
    height: 200px;
    border: 1px solid rgb(112, 112, 112);
    overflow: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 10px;
    position: relative;
    top: 0;
    max-width: 100%;
  }

  .table-data-ellipsis {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-data-ellipsis:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
    line-height: 1.2;
  }

  .tables-container {
    width: 100%;
  }

  /* VISIBILITY CHANGES BETWEEN DOCUMENT TYPE */
  #estimateModalEdit,
  #invoiceModalEdit,
  #recurring,
  #copy,
  #convert,
  .tables-container,
  #estimateCopy,
  #declineModalEdit {
    display: none;
    pointer-events: none;
  }

  :host([recipt]) .button--delete,
  :host([recipt]) #recurring,
  :host([recipt]) #copy,
  :host([recipt]) #convert,
  :host([recipt]) .tables-container,
  :host([recipt]) #estimateCopy,
  :host([recipt]) #declineModalEdit {
    display: none;
    pointer-events: none;
  }

  #declineModalEdit,
  #estimateModalEdit {
    display: flex;
    pointer-events: all;
  }

  #convert,
  #estimateCopy {
    display: flex;
    pointer-events: all;
  }

  :host([invoice]) #recurring,
  :host([invoice]) #copy,
  :host([invoice]) #invoiceModalEdit {
    display: block;
    pointer-events: all;
  }

  :host([invoice]) .tables-container {
    display: grid;
    pointer-events: all;
    height: auto;
  }

  /* Elemento | https://leadsboxinvoice.com/quotes/status/all */

  .bottom-side-left > div:nth-child(1) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  :host([invoice]) .bottom-side-left > div:nth-child(1) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  /* Elemento | https://leadsboxinvoice.com/quotes/status/all */

  #preview {
    grid-column: 1/-1;
    order: -1;
  }

  /* Elemento | https://leadsboxinvoice.com/quotes/status/all */

  #deleteForm {
    display: contents;
  }

  .button--marker {
    position: relative;

    &.sent::after {
      content: '';
      height: 10px;
      width: 10px;
      background-color: rgb(67, 194, 84);
      display: block;
      position: absolute;
      top: -2.5px;
      left: -2.5px;
      border-radius: 50%;
    }
  }

  .button--50px {
    width: 50px;
  }
}

//INVOICE STYLES
:host([invoice]) .lb-modal__chip-1 {
  background-color: #d49494;
  color: #cf1717;
}

:host([invoice]) .lb-modal__chip-1:after {
  content: 'Unpaid';
}

:host([invoice]) .lb-modal__chip-2 {
  background-color: #ffecce;
  color: #c27400;
}

:host([invoice]) .lb-modal__chip-2::after {
  content: 'Partial paid';
}

:host([invoice]) .lb-modal__chip-3 {
  background-color: #dcfadd;
  color: #1a4a11;
}

:host([invoice]) .lb-modal__chip-3::after {
  content: 'Paid';
}

.modal-layout {
  display: grid;
  grid-template-columns: 1fr 420px;
  grid-template-rows: 1fr min-content;
  column-gap: 50px;
  row-gap: 25px;
  width: 100%;
  height: 100%;
  grid-template-areas: 'main-top side-top' 'main-footer side-footer';

  .main-top {
    grid-area: main-top;
  }

  @mixin scroll-x {
    overflow-y: auto;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .side-top {
    grid-area: side-top;
    overflow: auto; // Revert to "scroll" if we encounter bugs
    scrollbar-gutter: stable;
    scroll-behavior: contain;

    @media (max-width: 768px) {
      @include scroll-x;
    }
  }

  .main-footer {
    grid-area: main-footer;
  }

  .side-footer {
    grid-area: side-footer;
  }

  section {
    border-bottom: 1px solid rgb(202, 202, 202);
    padding-bottom: 20px;

    & + section {
      padding-top: 20px;
    }

    .lb-modal__label {
      color: #797979;
      line-height: 1.5;
    }

    a {
      text-decoration: none;
    }

    a.active {
      font-weight: 600;
    }

    a.active img {
      rotate: 180deg;
      padding-right: 0px;
    }
  }

  .section--link img {
    padding-right: 10px;
    transition: all 0.3s ease-in;
  }

  .section--link:hover {
    cursor: pointer;

    img {
      rotate: 180deg;
      padding-right: 0px;
    }

    .lb-modal__data {
      font-weight: 600;
    }
  }

  .actions {
    margin-left: -1.5rem;

    section {
      margin-left: 1.5rem;
    }
  }

  .text-field + .text-field {
    margin-top: 0.75rem;
  }

  .d-flex {
    .text-field + .text-field {
      margin-left: 25px;
      margin-top: 0;
    }
  }

  .text-field .link {
    text-decoration: underline;
  }

  .sqr-btn {
    border: 0;
    border-radius: 10px !important;
    min-width: 45px;
    padding: 7.5px 12.5px;
    color: white;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-items: baseline;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
  }
}

.view-preview {
  position: absolute;
  top: 0;
  right: -50px;
  width: 100px;
  height: 100%;
}

.circle {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: sticky;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100000;
  color: white;
}

@media (max-width: 991px) {
  .modal-layout {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
    margin-bottom: 50px;
    row-gap: 25px;
    width: 100%;
    // height: 100%;
    grid-template-areas: 'main-top' 'side-top' 'side-footer' 'main-footer';

    .side-footer {
      margin-bottom: 4rem;
      padding-bottom: 4rem;
    }
  }
}

.modal__overflow-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 24ch;
  white-space: nowrap;
}
