$modal-backdrop-opacity: 0.1;
@import "bootstrap/scss/bootstrap";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
// Toastify overrides
$modal-backdrop-opacity: 0;
// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
// @import 'bootstrap/scss/variables-dark';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

:root {
  // This could be done better by editing the
  // bootstrap color variables (sass)
  --bs-blue: hsl(216, 98%, 52%);
  --bs-indigo: hsl(263, 90%, 51%);
  --bs-purple: hsl(261, 51%, 51%);
  --bs-pink: hsl(330, 67%, 52%);
  --bs-red: hsl(354, 70%, 54%);
  --bs-orange: hsl(27, 98%, 54%);
  --bs-yellow: hsl(45, 100%, 51%);
  --bs-green: hsl(152, 69%, 31%);
  --bs-teal: hsl(162, 73%, 46%);
  --bs-cyan: hsl(190, 90%, 50%);

  --bs-light-blue: hsl(216, 98%, 60%);
  --bs-light-indigo: hsl(263, 90%, 60%);
  --bs-light-purple: hsl(261, 51%, 60%);
  --bs-light-pink: hsl(330, 67%, 62%);
  --bs-light-red: hsl(354, 70%, 64%);
  --bs-light-orange: hsl(27, 98%, 64%);
  --bs-light-yellow: hsl(45, 100%, 61%);
  --bs-light-green: hsl(152, 68%, 41%);
  --bs-light-teal: hsl(162, 73%, 56%);
  --bs-light-cyan: hsl(190, 90%, 60%);

  --bs-box-shadow-blue: hsla(216, 98%, 60%, 0.3);
  --bs-box-shadow-indigo: hsla(263, 90%, 60%, 0.3);
  --bs-box-shadow-purple: hsla(261, 51%, 60%, 0.3);
  --bs-box-shadow-pink: hsla(330, 67%, 62%, 0.3);
  --bs-box-shadow-red: hsla(354, 70%, 64%, 0.3);
  --bs-box-shadow-orange: hsla(27, 98%, 64%, 0.3);
  --bs-box-shadow-yellow: hsla(45, 100%, 61%, 0.3);
  --bs-box-shadow-green: hsla(152, 69%, 41%, 0.3);
  --bs-box-shadow-teal: hsla(162, 73%, 56%, 0.3);
  --bs-box-shadow-cyan: hsla(190, 90%, 60%, 0.3);
}

$colors: (
  "primary": $primary,
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "warning": $yellow,
  "danger": $red,
  "info": $cyan,
  "success": $green
);

$btn-font-weight: $font-weight-semibold;

@each $name, $color in $colors {
  .btn-#{$name} {
    @include button-variant(
      $color: color-contrast($color),
      $background: $color,
      $border: $color,
      $hover-color: color-contrast($color),
      $hover-background: lighten($color, 10%),
      $hover-border: lighten($color, 10%)
    );
  }
}

@each $name, $color in $colors {
  .btn-outline-#{$name} {
    @include button-outline-variant($color);
  }
}

@each $name, $color in $colors {
  .btn-icon-outline-#{$name} {
    @include button-variant(
      $color: $color,
      $background: white,
      $border: transparent,
      $hover-background: tint-color($color, $btn-hover-bg-tint-amount)
    );
  }
}

@each $name, $color in $colors {
  .btn-icon-#{$name} {
    @include button-variant(
      $color: color-contrast($color),
      $background: $color,
      $hover-background: lighten($color, 10%),
      $hover-color: color-contrast(lighten($color, 10%)),
      $border: transparent,
      $hover-border: transparent
    );
  }
}
