/* RESET */
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
* {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}
p {
  margin-bottom: 0;
}
/* STYLES */
:root {
  --c-lateral-modal-width-left: 420px;
  --c-lateral-modal-width-right: 420px;
}
body {
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

// .lb-modal__wrapper {
//   position: sticky;
//   top: 30px;
// }

#lb-modal {
  position: absolute;
  top: 0;
  right: calc(
    (
        (var(--c-lateral-modal-width-left, 40vw)) +
          var(--c-lateral-modal-width-right, 40vw) + 110px
      ) * -1
  );
  z-index: 1000;
  width: calc(
    (var(--c-lateral-modal-width-left, 40vw)) +
      var(--c-lateral-modal-width-right, 40vw) + 110px
  );
  height: 100%;

  will-change: right;
  transition: right 1s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  will-change: right;
  transition: right 1s cubic-bezier(0.465, 0.183, 0.153, 0.946);

  /* This should be a mixin */
  opacity: 0;
  pointer-events: none;
}
#close {
  position: fixed;
  top: calc(2.5vh + 30px);
  right: 2.5vh;
  transform: translateX(1000px);
  z-index: 10000;
  transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946) 0.3s;

  /* This should be a mixin */
  pointer-events: none;
  opacity: 0;
}
.opened#lb-modal {
  right: calc((var(--c-lateral-modal-width-right, 40vw) + 55px) * -1);

  /* This should be a mixin */
  opacity: 1;
  pointer-events: all;
}
.opened#close {
  transform: translateX(0);
  transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946) 0.3s;

//   /* This should be a mixin */
  opacity: 100%;
  pointer-events: all;
}

.opened.expanded#lb-modal {
  right: 2.5vh;
}
.opened.expanded#close {
  right: calc(2.5vh + 30px);
}

.lb-modal__card {
  height: 95vh;
  height: 95dvh;
  width: 100%;
  position: sticky;
  top: 2.5vh;
  top: 2.5dvh;
  margin-top: 2.5vh;
  margin-top: 2.5dvh;
  margin-right: 10px;
  background-color: white;

  padding: 30px;
  border-radius: 30px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
}
.action-button {
  border: 0;
  background-color: #01334f;
  padding: 10px;
  color: white;
  border-radius: 10px;
  min-width: 50px;
  vertical-align: middle;
  font-size: 12px;
  cursor: pointer;
  display: block;
}
.action-button:hover {
  background-color: grey;
}

.modal_wrapper_for_safari_bug {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  // background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  pointer-events: none;
}

@media (max-width: 991px) {

  .left-side {
    overflow-y: initial;
  }

  #lb-modal {
    right: -100%;
    height: 100%;
    width: 0;
    overflow: hidden;
  }
  .opened#lb-modal,
  .opened.expanded#lb-modal {
    right: 0;
    width: 100%;
    overflow: visible;
  }
  .lb-modal__card {
    // position: static;
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0;
  }
  #close {
    top: 50px;
    right: 30px;
  }
  .h-md-100 {
    height: auto;
  }
}

.modal-overflow {
  margin-top: 30px !important;
  // overflow-y: scroll;
}
.modal-layout .side-top {
  margin-top: -30px;
  padding-top: 30px;
}
.side-top-overflow {
  overflow: visible;
  max-height: 100%;

  max-height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
