.Colors-Selector {
  &__wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}
