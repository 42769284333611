@mixin scroll-x {
  overflow-y: scroll;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin absolute-origin($top: 0, $left: 0) {
  position: absolute;
  top: $top;
  left: $left;
}

ul {
  list-style: none;
  padding: 0;
}

.navigation-wrapper {
  height: 100%;
  z-index: 1000;
  grid-column: 1/2;
  grid-row: 1/2;
}

.nav-inner {
  width: 0px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00334f;
  color: white;
  transition: width 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946) 0.3s;
  border-radius: 0 15px 15px 0;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  @include scroll-x;
  &.expand {
    width: 250px;
    pointer-events: all;
  }
}

.menu-btn {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}
.nav-mobile {
  background-color: #00334f;
  @include absolute-origin();
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  padding-left: 16px;
  img {
    max-width: 32px;
    height: auto;
  }
  .logo-box {
    background-color: #00334f;
    margin: -0.5rem;
    padding: 0.5rem;
    padding-left: 1.5rem;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 1.5rem;
    height: calc(48px + 1rem);
    display: flex;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .nav-inner {
    &,
    &.expand {
      width: 55px;
      &:hover {
        width: 250px;
        pointer-events: all;
        transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946) 0.3s;

        .logo-wrapper {
          transform-origin: center left;
          transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
          transform: scale(1.5);
          .logo {
            transform: translateX(0);
          }
        }
      }
    }
  }
}

.nav-header {
  background-color: #064f77;
  height: 100px;
  display: flex;
  align-items: center;

  .logo-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    padding-inline: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100%;
    width: auto;
    transform-origin: center left;
    transform: scale(1);
    transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946);
    .iso {
      flex: 0 0 35px;
      min-width: 0px;
      max-width: 60px;
      height: 100%;
    }

    .logo-mask {
      flex: 0 0 80px;
      min-width: 0px;
      height: auto;
      overflow: hidden;
    }
    .logo {
      width: 100%;
      max-width: 120px;
      height: auto;
      transition: transform 0.5s cubic-bezier(0.465, 0.183, 0.153, 0.946) 0.3s;
      transform: translateX(-100%);
    }

  }
  @media (max-width: 768px) {
    .logo-wrapper {
      transform-origin: center left;
      transform: scale(1.2);
      .logo {
        transform: translateX(0);
      }
      
    }
  }
}

.nav-body {
  img {
    width: 22px;
    height: 22px;
  }
  .navItemGrid {
    padding: 15px;
    display: grid;
    gap: 15px;
    grid-template-columns: 25px 1fr 25px;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    color: white;
    text-decoration: none;
    &.active {
      color: #00334f;
      background-color: white;
      font-weight: 500;
    }
  }
  li {
    &:hover {
      background-color: rgb(255, 255, 255);
      color: #00334f;
      cursor: pointer;
      .navItemGrid {
        color: #00334f;
      }
      .icon-nav {
        display: none;
        &-hover {
          display: block;
        }
      }
    }
    .navItemGrid.active .icon-nav {
      display: none;
      &-hover {
        display: block;
      }
    }
    .icon-nav-hover {
      display: none;
    }
  }
}

.active {
  font-weight: 500;
}

.closeLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.tabs {
  max-width: calc(30px + 100%);
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 768px) {
  .tabs {
    max-width: 100%;
  }
}
.tabs .nav {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.chiplist {
  max-width: calc(30px + 100%);
  margin-left: -15px;
  margin-right: -15px;
  fieldset {
    padding-left: 15px;
    &::after {
      content: '';
      display: block;
      clear: both;
      min-width: calc(15px - 0.5rem);
      height: 10px;
    }
  }
}

.navigation__nav-item {
  * {
    vector-effect: inherit;
  }
}

// Styles for sub menu items
// .nav-body {
//   li {
//     &:hover {
//       .navItemSubGrid {
//         width: auto;
//         height: auto;

//         display: block;
//       }
//       .subItemContainer {
//         background-color: rgb(255, 255, 255);
//         height: auto;
//         max-height: 500px;
//         pointer-events: all;
//         transition: all 0.8s cubic-bezier(0.465, 0.183, 0.153, 0.946) 0.3s;
//       }
//     }

//   }
// }

// .navItemSubGrid {
//   display: block;
//   padding: 10px 0;
//   padding-left: 60px;
//   text-decoration: none;
// }

// li .subItemContainer {
//   max-height: 0;
//   overflow: hidden;
//   pointer-events: none;
//   transition: all 0.8s cubic-bezier(0.465, 0.183, 0.153, 0.946) 0.3s;
// }

// .navItemSubGrid:hover {
//   background-color: #cad5da !important;
//   a {
//     text-decoration: none;
//   }
// }
