.react_table {
  margin-top: 10px;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  border-collapse: separate;
  border-spacing: 0 10px;
  tbody tr {
    background-color: white;
  }
  th {
    text-align: start;
    font-weight: 600;
    padding: 0 30px;
  }
  tbody td {
    border: 0;
    vertical-align: middle;
    padding: 30px;
  }

  .td,
  .th {
    .table-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 2;
      max-width: 500px;
    }
  }
  .td-add {
    text-align: center;
    border-radius: 5px;
  }
  .horizontal-overflow {
    overflow: hidden;
    .wrapper {
      overflow-x: auto;
    }
  }

  /* Added styles for mobile */

  --table-border: 1px solid var(--bs-gray-300);
  --table-spacing: 1rem;

  @media (max-width: 1200px) {
    tbody td {
      padding: 0;
      padding-top: var(--table-spacing);
    }

    tbody {
      grid-template-columns: 1fr;
      grid-gap: var(--table-spacing);
      display: grid;

      tr {
        padding: var(--table-spacing);
        padding-top: 0;
        margin: 0;
        border: 1px solid var(--bs-gray-300);
        border-radius: 8px;
        display: grid;
        cursor: pointer;
      }
      .accesor {
        display: block;
        color: gray;
        font-size: 0.65rem;
        padding-bottom: 0.25rem;
      }
      td {
        display: flex;
        flex-direction: column;
      }

      & td:empty {
        display: none;
      }
    }

    thead {
      display: none;
    }
  }
}
